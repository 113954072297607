import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {
  
  constructor(private readonly http: HttpClient) { }

  fetchNotificationList(query_string:string): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/notifications?type=${query_string}`);
  }

  updateNotification(id:string): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/notifications/${id}`);
  }

}
