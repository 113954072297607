import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { INPUT_ERROR_MESSAGE, USER_BENEFICIARY } from '../constants/stolt-constant';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CrewSelfService {

  private readonly subject = new Subject<any>();


  private readonly isDesktop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly pageRefresh$: BehaviorSubject<any> = new BehaviorSubject({ page: '', refresh: false });

  private readonly fillipino$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  // Constants for encryption and decryption
  public encryptionKey = CryptoJS.enc.Utf8.parse('Ma=+!I|^Y4,|+y3m7)jD98CO0p_+kQeS'); // 32 characters (256 bits)
  public iv = CryptoJS.enc.Utf8.parse('HlSSY1;T04i|-/d5'); // 16 characters (128 bits)

  constructor(private readonly platform: Platform) { }


  public encryptData(data: any) {
    const dataString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(dataString, this.encryptionKey, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  }

  public decryptData(encryptedData: any) {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, this.encryptionKey, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }

  public encryptHttpRequestBody(req: any) {
    const isUploadFileRequest = req.url.includes('/upload-file');    
    if (!isUploadFileRequest && req.body) {
      const encryptedReqBody = this.encryptData(req.body);  // Encrypt the body
      req = req.clone({
        body: { payload: encryptedReqBody }
      });
    }
    return req;
  }

  public getChangedFields(initialValue: any, currentValue: any): string[] {
    const changedFields: string[] = [];
    Object.keys(initialValue).forEach((key) => {
      const originalValue = this.normalizeFieldValue(initialValue[key]);
      const currentValueField = this.normalizeFieldValue(currentValue[key]);
      if (Array.isArray(originalValue) && Array.isArray(currentValueField)) {
        if (originalValue.length !== currentValueField.length ||
            !originalValue.every((value, index) => value === currentValueField[index])) {
          changedFields.push(key);
        }
      } else if (originalValue !== currentValueField) {
        changedFields.push(key);
      }
    });
    return changedFields;
  }
  

  public normalizeFieldValue(value: any): any {
    if (typeof value === 'string') {
      return value.toLowerCase().trim();
    }
    return value;
  }

  sendClickEvent(event: any) {
    this.subject.next(event); // Triggering an event
  }



  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  isDesktopSet() {
    this.platform.ready().then(() => {
      if (this.platform.width() < 768) {
        this.isDesktop$.next(false);
      }
      else {
        this.isDesktop$.next(true);
      }
    });
  }

  isDesktopGet() {
    return this.isDesktop$.asObservable();
  }

  pageRefreshSet(page: string, refresh: boolean) {
    this.pageRefresh$.next({ page, refresh });
  }

  pageRefreshGet() {
    return this.pageRefresh$.asObservable();
  }

  isFillipinoSet(userType:number) {
    let fillipino = USER_BENEFICIARY.FILIPINO_POOL_CODE.includes(userType);
    this.fillipino$.next(fillipino);
  }

  isFillipinoGet() {
    return this.fillipino$.asObservable();
  }



  dropdownOptions(data: any, id: string, name: string, toUpperCase = false) {
    let options: { id: any; name: any; }[] = [];
    data.forEach((element: any) => {
      if (element[id] && element[name]) {
        options.push({ id: element[id], name: (toUpperCase) ? element[name].toUpperCase() : element[name] });
      }

    });
    return options;
  }


  public showInputError(field: string, type: string, data: any) {
    if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('pattern')) {
      return this.errorMsg(field, type);
    }
    else if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('maxlength')) {
      return this.errorMsg(field, 'maxlength');
    }
    else if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('max')) {
      return this.errorMsg(field, 'max');
    }
    return '';
  }

  errorMsg(field: string, type: string) {
    if (type == 'string') {
      return INPUT_ERROR_MESSAGE.CHARACTOR_ONLY;
    }
    else if (type == 'number') {
      return INPUT_ERROR_MESSAGE.NUMBER_ONLY;
    }
    else if (type == 'special') {
      return INPUT_ERROR_MESSAGE.SPECIAL;
    }
    else if (type == 'maxlength') {
      return INPUT_ERROR_MESSAGE.MAXLENGTH;
    }
    else if (type == 'max') {
      return INPUT_ERROR_MESSAGE.PERCENTAGE_MAX;
    }
    else if (type == 'percentage') {
      return INPUT_ERROR_MESSAGE.PERCENTAGE_DATA_FORMET;
    }
    else {
      return '';
    }

  }


  /**************************** --------------END------END-----------  ************************************/
}