import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable, Subject } from 'rxjs';
import {INPUT_ERROR_MESSAGE } from '../constants/stolt-constant';

@Injectable({
  providedIn: 'root'
})
export class CrewSelfService {

  private readonly subject = new Subject<any>();

  public encryptionKey = CryptoJS.enc.Utf8.parse('12345678901234567890123456789012'); // 32 characters (256 bits)
  public iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 characters (128 bits)


  public encryptData(data:any) 
  {
    const dataString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(dataString, this.encryptionKey, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  }

  public decryptData(encryptedData:any) {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, this.encryptionKey, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }

  public getChangedFields(initialValue: any, currentValue: any): string[] {
    const changedFields: string[] = [];
    Object.keys(initialValue).forEach((key) => {
      const originalValue = this.normalizeFieldValue(initialValue[key]);
      const currentValueField = this.normalizeFieldValue(currentValue[key]);
      if (originalValue !== currentValueField) {
        changedFields.push(key);
      }
    });
    return changedFields;
  }

  public normalizeFieldValue(value: any): any {
    if (typeof value === 'string') {
      return value.toLowerCase().trim();
    }
    return value;
  }

  sendClickEvent(event:any) {
      this.subject.next(event); // Triggering an event
  }

  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }

  dropdownOptions(data:any,id:string,name:string,toUpperCase = false){
    let options: { id: any; name: any; }[] = [];
    data.forEach((element:any) => {
      if(element[id] && element[name]){
        options.push({id: element[id], name: (toUpperCase)? element[name].toUpperCase() : element[name] });
      }
      
    });
    return options;
  }


  public showInputError(field:string,type:string,data:any) 
  {
    if(data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('pattern')){
      return this.errorMsg(field,type);
    }
    else if(data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('maxlength')){
      return this.errorMsg(field,'maxlength');
    }
    return '';
  }

  errorMsg(field:string,type:string){
     if(type == 'string'){
        return INPUT_ERROR_MESSAGE.CHARACTOR_ONLY;
     }
     else if(type == 'number'){
      return INPUT_ERROR_MESSAGE.NUMBER_ONLY;
     }
     else if(type == 'special'){
      return INPUT_ERROR_MESSAGE.SPECIAL;
     }
     else if(type == 'maxlength'){
      return INPUT_ERROR_MESSAGE.MAXLENGTH;
     }
     else{
      return '';
     }
    
  }

   
  /**************************** --------------END------END-----------  ************************************/
}