<div class="ov-flow open-reporting theme-inner">
  <div class="bg-colour">
<ion-skeleton-text *ngIf="!isFrontImageLoaded || !isBackImageLoaded" animated="true" class="img-skelton"></ion-skeleton-text>
    <ng-container>
      <img *ngIf="selectedTab === 'Open Reporting'" (load)="onImageLoad('front')" [class.hide]="!isFrontImageLoaded" 
           src="../assets/img/Open-reporting-front.jpg" alt="Open Reporting"/>

      <img *ngIf="selectedTab === 'Open Reporting'" (load)="onImageLoad('back')" [class.hide]="!isBackImageLoaded" 
           src="../assets/img/Open-reporting-back.jpg" alt="Open Reporting"/>

      <img *ngIf="selectedTab === 'Stop Work Authority'" (load)="onImageLoad('front')" [class.hide]="!isFrontImageLoaded" 
           src="../assets/img/Stop-work-front.jpg" alt="Stop Work Authority"/>

      <img *ngIf="selectedTab === 'Stop Work Authority'" (load)="onImageLoad('back')" [class.hide]="!isBackImageLoaded" 
           src="../assets/img/Stop-work-back.jpg" alt="Stop Work Authority"/>
    </ng-container>
  </div>
</div>
