import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap, throwError, interval, Subscription } from 'rxjs';
import { StorageService } from './storage.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as signalR from '@microsoft/signalr';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { AuthApiService } from './auth-api.service';
import { UserApiService } from './user-api.service';
import { CrewSelfService } from './crew-self.service';
import { ErrorModalComponent } from 'src/app/auth/error-modal/error-modal.component';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly signOutSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authCode: string = '';
  public connectionError: string = '';
  public connectionId: string = '';
  public loginLink: string = '';
  public logoutLink: string = '';
  public isNative: boolean = Capacitor.isNativePlatform();
  public srHubconnection: signalR.HubConnection | undefined;
  public signoutInProgress:boolean = false;  
  public isLoginInProgress:boolean = false;
  private signalRToken:string = '';

  public isOnlineSubject = new BehaviorSubject<boolean>(false); // Default online
  isOnline$ = this.isOnlineSubject.asObservable().pipe(distinctUntilChanged()); // Observable to subscribe to
  
  public b2cAuthCodeBySignalR:string = '';
  private refreshTokenInProgress = false;
  private browserFinished:boolean = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);
  private connectionTimeSubscription: Subscription | undefined;

  constructor(
    private readonly router: Router,
    private readonly authApiService: AuthApiService,
    private readonly alertController: AlertController,
    private readonly _storageService: StorageService,
    private readonly userApiService: UserApiService,
    private readonly crewSelfService: CrewSelfService,
    private readonly modalController: ModalController,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {
    this.initializeBrowserListener();
  }  

  initializeBrowserListener(){
    Browser.addListener('browserFinished', () => { 
      console.log('AuthService: browserFinished invoked.');
      if(!this.browserFinished){
        this.srHubconnection?.stop().then(() => {
          console.log('AuthService: SignalR connection stopped.');
          this.invokeAuthGuardOnBrowserFinished();
        }).catch((err) => {
          console.error('Failed to close the browser: ', err);        
          this.invokeAuthGuardOnBrowserFinished();
        });
      } else {
        console.log('AuthService: browser intentionally closed.');
      }
    });
  }

  invokeAuthGuardOnBrowserFinished(){
    console.log('AuthService: AuthGuard invoked after browserFinished.');
    this.signalRToken = '';
    this.connectionTimeSubscription?.unsubscribe();
    this.isLoginInProgress = false;
    this.authGuard();
  }

  getSignOutPopup() {
    return this.signOutSubject.asObservable();
  }

  setSignOutPopup(bool: boolean) {
    this.signOutSubject.next(bool);
  }

  async showCopyAlert(content: string) {
    const alert = await this.alertController.create({
      header: 'Copy Content',
      message: `<strong>${content}</strong>`,
      buttons: [
        {
          text: 'Copy',
          handler: () => {
            this.copyToClipboard(content);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
  }

  copyToClipboard(content: string) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }

  async acquireB2CAccessToken(code: string) {
    const code_verifier = this._storageService.getDataFromStorage('code_verifier'); 
    if (!code || !code_verifier) {
      this.showLoginErrorModal();
    } else {    
      // call B2C access token api
      this.isOnlineSubject.value && this.authApiService.b2cTokenApi(code, code_verifier).subscribe({
        next: (response:any) => {
          console.log('AuthService: b2cTokenApi response', response)
          // clear code_verifier from local storage
          this._storageService.removeDataFromStorage('code_verifier');

          // set acquiringB2CToken to false and clear the auth code
          this.b2cAuthCodeBySignalR = '';

          // set login in progress to false
          this.isLoginInProgress = false;

          // validate the access token by refresh API
          this.authApiService.b2cRefreshTokenApi(response.refresh_token).subscribe({
            next: (refresh_response:any) => {
              
              // save access and refresh token in local storage
              this._storageService.putDataInStorage(
                'access_token',
                refresh_response.access_token
              );
              this._storageService.putDataInStorage(
                'refresh_token',
                refresh_response.refresh_token
              );          
              this.getApprovedUserDetail();

              // navigate to home page after getting the access_token
              this.router.navigate(['profile']);              
            },
            error: (err: any) => {
              this.authGuard();
            },
          });          
        },
        error: async (err: any) => {
          console.error('AuthService: Error fetching access_token:', err);
          this.showLoginErrorModal();
        },
      });
    }
  }

  public getApprovedUserDetail() {
  this.userApiService.fetchUserApprovedDetail()
      .subscribe({
        next: (response) => {
          if(response.status === 'success') {
            this.crewSelfService.isFillipinoSet(response.data.POOL_CODE);
            this.crewSelfService.isPoolCodeSet(response.data.POOL_CODE);
            this.crewSelfService.isSetActiveUser(response.data);
          }
        },
        error: (err: any) => {
          console.error('Error user approved detail:', err);
        }        
      }
    );
  }

  redirectToB2c(code_challange: string, logout?: boolean) {
    const state = {platform:'native', 'code_challange':code_challange};
    this.authApiService.signalRTokenApi(uuidv4(), state).subscribe({
      next: (response:any) => {    
        console.log('AuthService: signalRTokenApi', response)
        if(response?.data?.status === "SUCCESS"){
          const state = response.data.state;
          const signaRHubEndpoint = response.data.connection.url;
          this.signalRToken = response.data.connection.accessToken;
          if(this.signalRToken && signaRHubEndpoint) {
            this.srHubconnection = new signalR.HubConnectionBuilder()
              .withUrl(signaRHubEndpoint, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => this.signalRToken,
              })
              .configureLogging(signalR.LogLevel.Information)
              .build();

            this.registerSignalRListener(state);
            this.startSignalRHubConnection(state, code_challange, logout);
          } else {
            console.error('AuthService: SignalR Token or Endpoint not found');
            this.signoutInProgress = false;
            this.showLoginErrorModal();
          }
        } else {
          console.error('AuthService: Error fetching SignalR token:');
          this.signoutInProgress = false;
          this.showLoginErrorModal();
        }
      },
      error: (err: any) => {
        console.error('AuthService: Error fetching SignalR token:');
        this.signoutInProgress = false;
        this.showLoginErrorModal();
      },      
    });
  }

  registerSignalRListener(state:any){
    if(this.srHubconnection !== undefined){
      this.srHubconnection.on('newAuthCode', (code:string, return_state:string) => {
        this.connectionTimeSubscription?.unsubscribe();          
        Browser.close();
        this.signalRToken = '';
        this.browserFinished = true;
        if (this.srHubconnection) {
          this.srHubconnection.stop().then(() => {
            this.srHubconnection = undefined;              
            console.log('Connection closed');
          });
        }
        
        if (!this.b2cAuthCodeBySignalR && code && state && return_state === state) {  
          this.b2cAuthCodeBySignalR = code;                  
          this.acquireB2CAccessToken(code);          
        } else {
          this.showLoginErrorModal();
        }        
      });
    }
  }

  async startSignalRHubConnection(state:any, code_challange:string, logout?: boolean){
    try {
      if(this.srHubconnection){
        await this.srHubconnection.start();

        // set login in progress
        this.isLoginInProgress = true;

        // call the connection timer
        this.startConnectionTimer()
        this.browserFinished = false;

        if(logout) {
          Browser.open({ url: this.getB2CLogoutUrl(state) });
        } else {
          Browser.open({ url: this.getB2CLoginUrl(state, code_challange) });
        }
        this.signoutInProgress = false
      }
    } catch (err) {
      this.connectionError = err as string;
      setTimeout(this.startSignalRHubConnection, 5000);
    }
  }

  async startConnectionTimer() {
    this.connectionTimeSubscription = interval(500).subscribe(async () => {  
      if(this.isOnlineSubject.value && this.signalRToken){
        if(this.isSignalRTokenExpired()){
          console.log('AuthService: SR token expired, invoking the AuthGuard.', this.srHubconnection);
          this.signalRToken = '';        
          this.connectionTimeSubscription?.unsubscribe();
          if(!this.browserFinished) {
            this.browserFinished = true;
            Browser.close();
          }            
          this.authGuard();
        } else if (this.srHubconnection && this.srHubconnection['_connectionState'] !== 'Connected' && this.srHubconnection['_connectionState'] !== 'Connecting') {
          await this.srHubconnection.start();        
          console.log('AuthService: SR Connection re-started ' + (this.srHubconnection ? this.srHubconnection?.['_connectionState'] : "Not connected"))
        }
      } else {
        console.log("AuthService: " + (!this.signalRToken ? "No SR token found." : "Network unavailable"))
      }
    });
  }

  authGuard() {
    let _reqToken: any = this._storageService.getDataFromStorage('access_token');
    console.log('AuthService: AuthGuard invoked with token ' + _reqToken);
    if (!_reqToken && _reqToken == null && _reqToken == undefined) {      
      // generate a random state and save it in local storage
      this.isOnlineSubject.value && this.generateCodeVerifierAndChallenge().then((response) => {        
        if (this.isNative) {
          // move to auth component and then redirect to B2C login page

          // save the code_verifier in local storage
          this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

          // redirect to B2C login handler
          console.log('AuthService: AuthGuard > Redirecting to B2C.');
          this.redirectToB2c(response.codeChallenge);
          this.router.navigate(['auth']);
        } else {  
          this.authApiService.webTokenApi(uuidv4(), {platform:'web', code_challange: response.codeChallenge}).subscribe({
            next: (res:any) => {    
              // save the code_verifier in local storage
              this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

              // set login in progress
              this.isLoginInProgress = true;

              // redirect to B2C login page
              window.location.href = this.getB2CLoginUrl(res.state, response.codeChallenge);   
            },
            error: (err: any) => {
              console.error('Error fetching web state:', err);
              this.showLoginErrorModal();
            },      
          });
        }
      });
      
      return false;
    }
    console.log('AuthService: AuthGuard > login validated.');
    return true;
  }

  getB2CLoginUrl(state: string, code_challenge: string) {
    return (
      environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/authorize' +
      `?client_id=${environment.B2C_CLIENT_ID}` +
      `&response_type=code` +
      `&redirect_uri=${environment.B2C_REDIRECT_URI}` +
      `&response_mode=query` +
      `&scope=${environment.B2C_USER_LOGIN_SCOPE}` +
      `&code_challenge=${code_challenge}` +
      `&state=${state}` +
      `&code_challenge_method=S256`
    );
  }

  getB2CLogoutUrl(state: string) { 
    return (
      environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/logout' +
      `?post_logout_redirect_uri=${environment.B2C_LOGOUT_REDIRECT_URI}` +
      `&state=${state}`
    );
  }

  signOut() {
    if(!this.signoutInProgress){
      this.signoutInProgress = this.isOnlineSubject.value;
      this._storageService.clearStorage();
      this.isOnlineSubject.value &&  this.generateCodeVerifierAndChallenge().then((response) => {  
        if (this.isNative) {

          // save the code_verifier in local storage
          this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

          // redirect to B2C logout handler
          this.redirectToB2c(response.codeChallenge, true);
          this.router.navigate(['auth']);
        } else {    
          this.authApiService.webTokenApi(uuidv4(), {platform:'web', code_challange: response.codeChallenge}).subscribe({
            next: (res:any) => {
              // save the code_verifier in local storage
              this._storageService.putDataInStorage('code_verifier', response.codeVerifier);
              this.signoutInProgress = false;

              // set login in progress
              this.isLoginInProgress = true;

              // redirect to B2C logout page
              window.location.href = this.getB2CLogoutUrl(res.state); 
            },
            error: (err: any) => {
              this.signoutInProgress = false;
              console.error('Error fetching web state:', err);
              this.showLoginErrorModal();
            },      
          });
        }
      });
    }
  }

  async generateCodeVerifierAndChallenge() {
    // Generate a random code_verifier of 128 characters
    const codeVerifier = this.generateRandomString(128); 

    // Generate the code_challenge from the code_verifier 
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);  

    return { codeVerifier, codeChallenge };
  }

  // Helper function to generate a random string of a given length  
  generateRandomString(length: number) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let randomString = '';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
      randomString += charset[randomValues[i] % charset.length];
    }
    return randomString;
  }
  
  // Helper function to generate a code_challenge from a code_verifier  
  async generateCodeChallenge(codeVerifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return this.base64UrlEncode(new Uint8Array(digest));
  }
  
  // Helper function to Base64 URL-encode an array of bytes
  base64UrlEncode(buffer: Uint8Array) {
    return btoa(String.fromCharCode(...buffer))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  refreshAccessToken(): Observable<string> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.asObservable() as any;
    } else {
      this.refreshTokenInProgress = true;
      const refresh_token:any = this._storageService.getDataFromStorage('refresh_token');
      return this.authApiService.b2cRefreshTokenApi(refresh_token).pipe(
        tap((response:any) => {
          console.log("AuthService: Access token refreshed.");
          this._storageService.putDataInStorage(
            'access_token',
            response.access_token
          );
          this._storageService.putDataInStorage(
            'refresh_token',
            response.refresh_token
          );
          this.refreshTokenInProgress = false;
        }),
        map(response => response.access_token),
        catchError(err => {
          console.log("AuthService: error while refreshing token.", err);
          return throwError(() => err);
        })
      );
    }
  }

  isTokenExpired(): boolean {
    const token = this._storageService.getDataFromStorage('access_token');
    if (!token) return true;

    let expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    console.log("Access token expiring in " + (expiry - (Math.floor(new Date().getTime() / 1000))) + " seconds.");
    return (Math.floor(new Date().getTime() / 1000)) >= expiry;
  }

  async showLoginErrorModal(loginState:string=''){
    if(this.isOnlineSubject.value){
      const modal = this.modalController.create({
        component: ErrorModalComponent,
        cssClass: 'delete-modal',
        componentProps: {         
          loginState: loginState,
        },
        backdropDismiss:false
      });

      (await modal).present();
    }
  }

  isSignalRTokenExpired(): boolean {
    const token = this.signalRToken
    if (!token) return true;

    let expiry = (JSON.parse(atob(token.split('.')[1]))).exp;    
    return (Math.floor(new Date().getTime() / 1000)) >= expiry;
  }
}