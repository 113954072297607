export const API_ENDPOINTS = {
    AUTH: {
      LOGIN: '/auth/login',
      LOGOUT: '/auth/logout',
      REFRESH_TOKEN: '/auth/refresh-token',
      ACCESS_TOKEN: '/member_auth'
    },

    HOME:{

    }
  
  };
  