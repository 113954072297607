import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {
  
  constructor(private readonly http: HttpClient) { }


  fetchDocumentDetail(id:string): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/documents/${id}`);
  }

  fetchCategoryType(endpoint:string): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/master-data/${endpoint}`);
  }

  fetchFormElementsForType(endpoint:string): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/master-data/${endpoint}`);
  }

  fetchDocumentList(query_string:string): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/documents?${query_string}`);
  }

  addDocumentData(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/documents`, postData);
  }


  deleteDocument(id: string) {
    return this.http.delete(`${environment.CREW_APP_BACKEND_API_BASE_URL}/documents/${id}`);
  }
}
