<ion-header class="navigation-header">
  <ion-toolbar class="mobile-only-toolbar">
    <ion-buttons *ngIf="title==='Search results'" slot="start" class="df-c buttons-first-slot" (click)="goBack()">
      <snl-icon iconType="svg"  icon="icon/chevron-left"></snl-icon>
    </ion-buttons>
    <ion-buttons  *ngIf="title!=='Search results'"  slot="start" class="df-c buttons-first-slot" (click)="openMenu()">
      <snl-icon iconType="svg" icon="icon/menu"></snl-icon>
    </ion-buttons>
    <ion-title class="text__center">
      <span class="sub-heading-s1 color__primary">{{title}}</span>
    </ion-title>
    <ion-buttons slot="end" class="df-c">
      <ion-button *ngIf="title !== 'Notifications'" routerLink="/notifications">
        <snl-icon iconType="svg" icon="icon/notification"></snl-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>