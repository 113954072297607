import { Injectable } from '@angular/core';
import { DOCUMENTS_TABS, NOTIFICATION_SECTION } from '../constants/stolt-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentApiService } from './document-api.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor( 
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly documentApiService:DocumentApiService,
  ) {}


  notificationTitle(section:string){
    let obj = {icon:'icon/user',title:''};
    if([NOTIFICATION_SECTION.PROFILE_SECTION,NOTIFICATION_SECTION.FAMILY_SECTION,NOTIFICATION_SECTION.BENEFICIARY_SECTION].includes(section)){
      obj.icon = 'icon/user';
      obj.title = 'Profile';
    }
    else if([NOTIFICATION_SECTION.DOCUMENT_SECTION].includes(section)){
      obj.icon = 'icon/files';
      obj.title = 'Document';
    }
    else if([NOTIFICATION_SECTION.EXPENSE_SECTION,NOTIFICATION_SECTION.CASH_ADVANCE_SECTION,NOTIFICATION_SECTION.BENEVOLENT_SECTION,NOTIFICATION_SECTION.PAYSLIP_SECTION].includes(section)){
      obj.icon = 'icon/dollar-sign';
      obj.title = 'Payroll & expenses';
    }
    else if([NOTIFICATION_SECTION.ACTIVITY_SECTION].includes(section)){
      obj.icon = 'icon/anchor';
      obj.title = 'Activities';
    }
    else if([NOTIFICATION_SECTION.FORM_SECTION].includes(section)){
      obj.icon = 'icon/messages';
      obj.title = '360 feedback & survey';
    }
    return obj;
  }

  notificationAction(data:any){
    if(data.SECTION == NOTIFICATION_SECTION.DOCUMENT_SECTION){
      if(data.REC_ID){
         if(data.NOTIFICATION_TYPE == 'Expired'){
          this.router.navigate(
            ['/documents'],
            { queryParams: { tab: 'EXPIRED',type: 'view', id:data.REC_ID } }
          ); 
         }
         else{
          this.documentDetail(data);
         }
      }
    }
    else if(data.SECTION == NOTIFICATION_SECTION.PROFILE_SECTION){
      this.router.navigate(['profile']);
    }
    else if(data.SECTION == NOTIFICATION_SECTION.FAMILY_SECTION){
      if(data.REC_ID){
        this.router.navigate(['profile'],{queryParams:{tab:'Family',id:data.REC_ID}});
      }
      else{
        this.router.navigate(['profile'],{queryParams:{tab:'Family', type:'add'}});
      }
    }
    else if(data.SECTION == NOTIFICATION_SECTION.BENEFICIARY_SECTION){
      if(data.REC_ID){
        this.router.navigate(['profile'],{queryParams:{tab:'Beneficiary'}});
      }
      else{
        this.router.navigate(['profile'],{queryParams:{tab:'Beneficiary', type:'add'}});
      }
    }
    else if(data.SECTION == NOTIFICATION_SECTION.EXPENSE_SECTION){
      if(data.REC_ID){
        this.router.navigate(['payroll-expenses'],{queryParams:{tab: 'Expenses',id:data.REC_ID}});
      }
    }
    else if(data.SECTION == NOTIFICATION_SECTION.CASH_ADVANCE_SECTION){
      if(data.REC_ID){
        this.router.navigate(['payroll-expenses'],{queryParams:{tab: 'Cash advances',id:data.REC_ID}});
      }
    }
    else if(data.SECTION == NOTIFICATION_SECTION.BENEVOLENT_SECTION){     
      this.router.navigate(['payroll-expenses'],{queryParams:{tab: 'Benevolent fund'}});
    }
    else if(data.SECTION == NOTIFICATION_SECTION.PAYSLIP_SECTION){
      this.router.navigate(['payroll-expenses'],{queryParams:{tab: 'Payslips'}});
    }
    else if(data.SECTION == NOTIFICATION_SECTION.ACTIVITY_SECTION){
      this.router.navigate(['activities']);
    }
    else if(data.SECTION == NOTIFICATION_SECTION.FORM_SECTION){
      this.router.navigate(['feedback-survey/form',data.REC_ID]);
    }
  }

  documentDetail(data:any){
    this.documentApiService.fetchDocumentDetail(data.REC_ID)
    .subscribe({
      next: (response) => {
        let tab = DOCUMENTS_TABS.find(it => it.value == response.data.STATUS);
        if(tab){
          this.router.navigate(
            ['/documents'],
            { queryParams: { tab: tab.key,type: 'view', id:data.REC_ID } }
          ); 
        }
      },
    });
  }

  /**************************** --------------END------END-----------  ************************************/
}