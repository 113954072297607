export const STOLT_CONST = Object.freeze({
  SUCCESS_STATUS: 'success',

});

export const NOTIFICATION_MESSAGE = Object.freeze({
  FILE_SIZE_EXCEEDS: 'The file size exceeds the maximum allowed limit of 5MB. Please upload a smaller file.',
  FILE_SUPPORT_FORMET: 'Supported file format only JPEG and PNG.',
  DOCUMENT_LIMIT_EXCEED: 'Only 3 files are allowed.',
  ALLOTMENT_LIMIT_EXCEED: 'Only 2 beneficiaries are allowed.',
  FILIPINO_ALLOTMENT_LIMIT_EXCEED: 'Only 3 allotment beneficiaries are allowed.',
  BOW_LIMIT_EXCEED: 'Only 1 BOW beneficiary are allowed.',
  BENEFICIARY_SAVED_SUCCESS:'Your beneficiary was saved successfully.',
  BENEFICIARY_SUBMITTED_SUCCESS:"Your beneficiaries were submitted for approval.",
  BENEFICIARY_ONE_SUBMITTED_SUCCESS:"Your beneficiary was submitted for approval.",
  DRAFT:"Your details were saved successfully.",
  PENDING_APPROVAL:"Your details were saved and submitted for approval.",
});


export const USER_STATUS = Object.freeze({
  APPROVED: 'Approved',
  PENDING_APPROVAL: 'Pending approval',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  REQUESTE_FOR_DELETION:'Submitted for deletion',
  MARKED_FOR_DELETION:'Marked for deletion'
})

export const DEFAULT_ACCCOUNT = Object.freeze({
   BANK: ['MARTRUST CORPORATION LIMITED','MARITIME PAYMENT SOLUTIONS LLC'],
   ACCOUNT:'123454321'
});

export const USER_BENEFICIARY = Object.freeze({
  FILIPINO_POOL_CODE:[13009],
  FILIPINO_ALLOTMENT_LIMIT:3,
  OTHER_ALLOTMENT_LIMIT:2
});

export const BENEVOLENT_FUND = Object.freeze({
   POOL_CODE:[13004,13013,13005,13009],
});


export const SUCCESS_MESSAGE = Object.freeze({
   DRAFT:"Your details were saved successfully.",
   PENDING_APPROVAL:"Your details were saved and submitted for approval.",
});

export const BTN_TEXT = Object.freeze({
  SAVE_AS_DRAFT: 'Save as draft',
  CANCEL_DRAFT: 'Cancel draft',
  UPDATE_DRAFT: 'Update draft',
  SAVE_AND_SUBMIT: 'Save and submit',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  DELETE: 'Delete',
});

export const DOCUMENTS_STATUS = Object.freeze({
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
});

export const EXPENSE_STATUS = Object.freeze({
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
});

export const STATUS = Object.freeze({
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  DRAFT: 'Draft',
  REJECTED: 'Rejected',
});

export const STATUS_SORT = Object.freeze({
  DATA: [STATUS.DRAFT,STATUS.REJECTED,STATUS.PENDING_APPROVAL,STATUS.APPROVED],
  
});

export const DOCUMENTS_TABS = Object.freeze([
    { key: 'IN_PROGRESS', value: 'In progress'},
    { key: 'APPROVED', value: 'Approved' },
    { key: 'REJECTED', value: 'Rejected' },
    { key: 'EXPIRED', value: 'Expired' }
]);

export const EXPENSE_TABS = Object.freeze([
  { key: 'IN_PROGRESS', value: 'In progress'},
  { key: 'APPROVED', value: 'Approved' },
  { key: 'REJECTED', value: 'Rejected' },
  { key: 'EXPIRED', value: 'Expired' }
]);

export const PAYROLL_TABS = Object.freeze([
  { key: 'Expenses', value: 'Expenses'},
  { key: 'Cash advances', value: 'Cash advances' },
  { key: 'Benevolent fund', value: 'Benevolent fund' },
  { key: 'Payslips', value: 'Payslips' }
]);

export const CURRENCY_LIST = Object.freeze([
  { id: 'USD', value: 'USD', name: 'USD', symbol: '$' },
  { id: 'PHP', value: 'PHP', name: 'PHP', symbol: '₱' }
]);

export const USER_STATUS_LABEL = Object.freeze([
  { status: 'Approved', label: 'Approved on', filed: 'APPROVED_ON', edit: true},
  { status: 'Draft', label: '',edit: false ,filed: 'REQUESTED_ON' },
  { status: 'Pending approval', label: 'Submitted on',edit: false , filed: 'REQUESTED_ON'},
  { status: 'Rejected', label: 'Rejected on',edit: true, filed: 'REQUESTED_ON' },
  { status: 'Submitted for deletion', label: 'Submitted on',edit: false, filed: 'REQUESTED_ON' },
  { status: 'Marked for deletion', label: 'Marked on',edit: true, filed: 'REQUESTED_ON' }
]);


export const INPUT_ERROR_MESSAGE = Object.freeze({
  CHARACTOR_ONLY: 'Special characters and numbers are not allowed.',
  NUMBER_ONLY: 'Only numbers are allowed.',
  SPECIAL: 'Special characters are not allowed.',
  MAXLENGTH:'You have reached the maximum characters limit for this field.',
  MAX:'You have reached the maximum limit for this field.',
  PERCENTAGE_DATA_FORMET:'Only 3 numbers and 2 decimals are allowed.',
  PERCENTAGE_MAX:'Please enter a value between 0% and 100%.'
})


export const DOCUMENT_CATEGORY_TYPE = {
  TRAVEL: "TRAVEL",
  MEDICAL: "MEDICAL",
  LICENSES: "LICENSES/CONTRACTS",
  TRAINING: "TRAINING",
};

export const DOCUMENT_DOC_TYPE = {
  "Travel": "travel",
  "Medical": "medical",
  'Licenses/Contracts': "licenses",
  "Training": "training",
   "Flag state": "flagstate"

};

export const MEDICAL_FIT_UNFIT_STATUS = {
  "FIT WITHOUT RESTRICTIONS": "FIT WITHOUT RESTRICTIONS",
  "UNFIT": "UNFIT",
  "FIT WITH RESTRICTIONS": "FIT WITH RESTRICTIONS",
  "NOT APPLICABLE": "NOT APPLICABLE"
};

export const MEDICAL_NEG_POS_STATUS = {
  NEGATIVE: "NEGATIVE",
  POSITIVE: "POSITIVE",
};

export const TRAVEL_DOC_TYPE = {
  PASSPORT: "PASSPORT",
  VISA: "VISA",
  VACCINATION: "VACC",
  SEAMANBOOK: "SBOOK"
}

export const TRAINING_INT_EXT_STATUS = {
  N: "INTERNAL",
  Y: "EXTERNAL"
};

export const VISA_ENTRY_TYPE = {
  "1 Entry": "1 Entry",
  "2 Entries": "2 Entries",
  "Multiple Entries": "Multiple Entries",
}

export const VISA_DURATION_TYPE = {
  "60 Days": "60 Days",
  "90 Days": "90 Days",
  "120 Days": "120 Days",
  "180 Days": "180 Days"
}

export const VISA_DURATION_MCV_TYPE = {
 "3 years": "3 years"
}


export const DOCUMENT_API_CONSTANT = {
  MEDICAL: "medical-type",
  TRAINING: "training-type",
  TRAVEL: "travel-list",
  LICENSES: "license-type",
  FALGSTATE: ""
}

export const EXPENSE_API_CONSTANT = {
  SIGN_ON_OFF: "SIGN_ON_OFF",
  MEDICAL_SAFETY: "MEDICAL_SAFETY",
  MEDICAL_CLAIM: "MEDICAL_CLAIM",
  TRAINING: "TRAINING",
  FALGSTATE: ""
}

interface DocumentCategoryDetails {
  key_code: string;
  key_desc: string;
}

interface ExpenseCategoryDetails {
  key_code: string;
  key_desc: string;
}

export const DOCUMENT_CATEGORY_KEY_MAP: {
  [key: string]: DocumentCategoryDetails;
} = {
  MEDICAL: {
    key_code: 'MEDICALTYPE_CODE',
    key_desc: 'DESCRIPTION'
  },
  TRAINING: {
    key_code: 'TRAINING_CODE',
    key_desc: 'TRAINING_DESCRIPTION'
  },
  TRAVEL: {
    key_code: 'DOCCATEGORY_CODE',
    key_desc: 'DESCRIPTION'
  },
  LICENSES: {
    key_code: 'LICENSE_CODE',
    key_desc: 'LICENSE_DESCRIPTION'
  }
} as const;

export const EXPENSE_CATEGORY_KEY_MAP: {
  [key: string]: ExpenseCategoryDetails;
} = {
  SIGN_ON_OFF: {
    key_code: 'TYPE_CODE',
    key_desc: 'TYPE_NAME'
  },
  MEDICAL_SAFETY: {
    key_code: 'TYPE_CODE',
    key_desc: 'TYPE_NAME'
  },
  MEDICAL_CLAIM: {
    key_code: 'TYPE_CODE',
    key_desc: 'TYPE_NAME'
  },
  TRAINING: {
    key_code: 'TYPE_CODE',
    key_desc: 'TYPE_NAME'
  }
} as const;

// Define an interface for Document Fields
interface DocumentField {
  label: string;
  key: string;
  type: string;
  split: boolean;
  postion?: number; // Optional, since not all fields have this property
}

// Define an interface for Categories
type DocumentCategory = 'TRAVEL' | 'MEDICAL' | 'LICENSES' | 'TRAINING';

// Define an interface for Expense
type ExpenseCategory = 'SIGN_ON_OFF' | 'MEDICAL_SAFETY' | 'MEDICAL_CLAIM' | 'TRAINING';


type DocumentFieldType = {
  label: string;
  key: string;
  type: string;
  options?: any;
  split: boolean;
  formControlName?: string,
  position?: number;
  condition_on?: string;
  optionKey?: string;
  exclude?: string[];
  excludeKey?: string;
  required?: boolean// Add optional properties if they exist
};

type ExpenseFieldType = {
  label: string;
  key: string;
  type: string;
  options?: any;
  split: boolean;
  formControlName?: string,
  position?: number;
  condition_on?: string;
  optionKey?: string;
  exclude?: string[];
  excludeKey?: string;
  required?: boolean// Add optional properties if they exist
};

type DocumentFieldsMap = {
  [key in DocumentCategory]: {
    [key: string]: DocumentFieldType[];
  };
};

type ExpenseFieldsMap = {
  [key in ExpenseCategory]: {
    [key: string]: ExpenseFieldType[];
  };
};

// Define the DOCUMENT_FIELDS constant with the appropriate type

export const ADD_DOCUMENT_FIELDS: any = {
  TRAVEL: {
    PASS: [
      { label: 'Passport number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true, key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SBOOK: [
      { label: "Seaman's document type", required: true, key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Document number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true,key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
     
    ],
    VISA: [
      { label: 'Visa type', key: 'SUB_TYPE_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Visa entry', key: 'VISA_ENTRY', type: "select",options:VISA_ENTRY_TYPE, split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER','VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa duration', key: 'VISA_DURATION', options:[], type: "select-child", split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER', 'VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa number', key: 'DOC_NO',required: true, type: "string", split: false },
      { label: 'Visa country', key: 'COUNTRY_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued country', key: 'VISADOC_COUNTRY',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
    ],
    VACC: [
      { label: "Vaccination type", required: true,key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Comments', key: 'COMMENTS',required: false, type: "string", split: true, exclude: ['VACCYF'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE', required: true,type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  MEDICAL: {
    PREEMPL: [
      { label: 'Fit/Unfit',required: true, key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS, split: true },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }
    ],
    DRUGALCO: [
      { label: 'Positve Negative',required: true, key: 'COMMENTS', type: "select", options: MEDICAL_NEG_POS_STATUS, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SUPPMED: [
      { label: 'Fit/Unfit', required: true,key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }

    ]
  },
  LICENSES: {
    FORM: [
      { label: 'Number', required: true,key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued at',required: true, key: 'ISSUE_AT', type: "select-child", options: [], split: false },
      { label: 'Issued by (optional)', key: 'ISSUE_BY', type: "string", split: false },
      { label: 'Issued date',required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  TRAINING: {
    FORM: [
      { label: 'Number',required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Start date',required: true, key: 'START_DATE', type: "date", split: false },
      { label: 'End date',required: true, key: 'END_DATE', type: "date", split: false },
      { label: 'Expiry date',required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
      { label: 'Internal or external',required: true, key: 'INTERNAL', type: "select", options: TRAINING_INT_EXT_STATUS, split: false },
      { label: 'Conducted at (optional)', key: 'CONDUCT_AT', type: "select-child", options: [], split: false },
    ]
  }

};

export const DOCUMENT_FIELDS = Object.freeze({
  TRAVEL: {
     PASS:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Passport number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
      ],
      SBOOK:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Seaman's document type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Document number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
      VISA:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Visa type',key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Visa entry',key:'COMMENTS',type:"string",split:true,postion:0,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa duration',key:'COMMENTS',type:"string",split:true,postion:1,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa number',key:'DOC_NO',type:"string",split:false},
        {label:'Visa country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued country',key:'VISADOC_COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
      ],
      VACC:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Vaccination type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Comment',key:'COMMENTS',type:"string",split:false,exclude:['VACCYF']},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
  },
  MEDICAL:{
    PREEMPL:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1},
    ],
    DRUGALCO:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Positve Negative',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
    ],
    SUPPMED:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1}
    ]
  },
  LICENSES: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Issued at',key:'ISSUE_AT_NAME',type:"string",split:false},
    {label:'Issued by',key:'ISSUE_BY',type:"string",split:false},
    {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
  ],
  FLAGSTATE: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Issued at',key:'ISSUE_AT_NAME',type:"string",split:false},
    {label:'Issued by',key:'ISSUE_BY',type:"string",split:false},
    {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
  ],
  TRAINING: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Start date',key:'START_DATE',type:"date",split:false},
    {label:'End date',key:'END_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
    {label:'Internal or external',key:'INTERNAL_NAME',type:"string",split:false},
    {label:'Conducted at',key:'CONDUCT_NAME',type:"string",split:false},
  ]
});

// Define the EXPENSE_FIELDS constant with the appropriate type


export const ADD_EXPENSE_FIELDS: any = {
  SIGN_ON_OFF: {
    PUBLIC_TRANSPORTATION:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child"},
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
      { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'},
    ],
    RENTAL_CAR:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MILEAGE_PRIVATE_CAR:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Kilometers', required: false, key: 'KILOMETER', type: "string",errorType:'percentage',pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: false, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
      { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    TRANSPORTATION:[
        { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
         { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
        { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
        { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
        { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
        { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
        { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
        { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
       { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    VISA_FEE:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
         { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
        { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
        { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
       { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
        { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
        { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
        { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
       { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    ADDITIONAL_VISA_COSTS:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
      { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    TRAVELLING_ALLOWANCE:[
    { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
     { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
    { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
    { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
    { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
    { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
    { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
    { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
    { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    FAMILY_TRAVEL_ALLOWANCE:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    ACCOMMODATION:[
      { label: 'Ship', required: true, key: 'SHIP',type: "select-child" },
      { label: 'Accommodation days', required: true, key: 'ACCCOM_DAYS', errorType:'percentage', type: "string", pattern:"^[0-9]{0,3}$"},
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MEALS:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
  },
  MEDICAL_SAFETY: {
    PUBLIC_TRANSPORTATION: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MILEAGE: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Kilometers', required: false, key: 'KILOMETER', type: "string",errorType:'percentage',pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    RENTAL_CAR: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    ANNUAL_MEDICAL: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    DA: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    BLOOD_TEST: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRANSPORTATION: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    VACCINATION: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    SAFETY_SHOES: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ]
  },
  MEDICAL_CLAIM: {
    MEDICAL_CLAIM_REIMBURSEMENT: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Injury', required: false,key: 'INJURY', type: "string",maxLength:'15',errorType:'special',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Injury date',required: false, key: 'INJURY_DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,5}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
  },
  TRAINING: {
    MEALS: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    PUBLIC_TRANSPORTATION: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MILEAGE_PRIVATE_CAR: [
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Kilometers', required: false, key: 'KILOMETER', type: "string",errorType:'percentage',pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    RENTAL_CAR: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
       { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    ACCOMMODATION: [
      { label: 'Accommodation days', required: true, key: 'ACCCOM_DAYS', errorType:'percentage', type: "string", pattern:"^[0-9]{0,3}$"},
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    VISA_FEE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    EXAMINATION_FEE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRAVELLING_ALLOWANCE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRANSPORTATION: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    ADDITIONAL_VISA_COSTS: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    FAMILY_TRAVEL_ALLOWANCE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRAINING_ALLOWANCE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    VACCINATION: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRAINING_COURSE_FEE: [
      { label: 'Training course', required: true, key: 'TRAINING_COURSE_FEE',type: "select-child",displayKey: 'TRAINING_COURSE_FEE_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ]
  }

};


export const REQUESTED_CASH_FIELDS = Object.freeze({
  RE_JOINING_BONUS: [
    { label: 'Type',required: true, key: 'TYPE_CODE', type: "label-string",size:'4',value:'Rejoining bonus'},
    { label: 'Beneficiary',required: true, dropdownKey:"BENEFICIARY", key: 'BENEFICIARY_ID', type: "dropdown",size:'6'},
    { label: 'Amount (USD)',required: true, key: 'AMOUNT', type: "string",size:'3',pattern:"^[0-9]+",errorType:'max'},
  ],
  CASH_ADVANCE: [
    { label: 'Type',required: true, key: 'TYPE_CODE', type: "label-string",size:'4',value:'Cash advance'},
    { label: 'Beneficiary',required: true, dropdownKey:"BENEFICIARY", key: 'BENEFICIARY_ID', type: "dropdown",size:'6'},
    { label: 'Amount (USD)',required: true, key: 'AMOUNT', type: "string",size:'3',pattern:"^[0-9]+",errorType:'max'},
  ],
  RE_JOINING_CASH_ADVANCE: [
    { label: 'Type',required: true, dropdownKey:"TYPE", key: 'TYPE_CODE', type: "dropdown",size:'4'},
    { label: 'Beneficiary',required: true, dropdownKey:"BENEFICIARY", key: 'BENEFICIARY_ID', type: "dropdown",size:'6'},
    { label: 'Amount (USD)',required: true, key: 'AMOUNT', type: "string",size:'3',pattern:"^[0-9]+",errorType:'max'},
  ],
});



