import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { CrewSelfService } from '../services/crew-self.service';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private crewSelfService: CrewSelfService,
  ) {}

  ngOnInit() {
    const state: string = this.route.snapshot.queryParamMap.get('state') || '';
    const code: string = this.route.snapshot.queryParamMap.get('code') || '';
    const logout: string = this.route.snapshot.queryParamMap.get('logout') || '';

    if (state && (code || logout)) {
      interface StateData {
        id: string;
        platform: 'web' | 'native';
        [code_challange: string]: any;
      }

      const state_data = jwtDecode<StateData>(state);
      const platform = state_data.platform;
      console.log(`State: `, state_data);
      if(code) {
        if (platform === 'native') {          
          const options: HttpOptions = {
            url: `${environment.AUTH_API_URL}/broadcast`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: { state:state, code, userId: state_data.id},
          };
          CapacitorHttp.post(options)
            .then((response) => {
              console.log('Response', response);
            })
            .catch((error) => {
              console.error('Error', error);
            });
        } else {
          this.crewSelfService.getAccessToken(code);
        }
      } else {
        console.log('Login url', this.crewSelfService.getB2CLoginUrl(state, state_data['code_challange']));
        window.location.href = this.crewSelfService.getB2CLoginUrl(state, state_data['code_challange']);
      }
    } else {
      console.error('Invalid request');
    }
  }
}

