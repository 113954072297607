import { Component, HostListener, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Router,NavigationEnd,ActivatedRouteSnapshot} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { CrewSelfService } from 'src/app/shared/services/crew-self.service';
import { UserApiService } from '../app/shared/services/user-api.service';
import { Subscription } from 'rxjs';
import { StorageService } from './shared/services/storage.service';
import { AuthApiService } from './shared/services/auth-api.service';
import { AuthService } from './shared/services/auth.service';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit{

  public sidebar = true;
  public isDesktop = false;
  public subscription: Subscription;
  menuType: string = 'overlay';  
  public isNative:boolean = false;
  public activeMenuItem :string = 'home';
  public pageTitle :string = 'Home';
  public bottomMenuOptions: any = [];
  public isSkelton:boolean = true;
  public device:string = '';
  public nativeClass:string = 'web';
  public isCollapsed:boolean = false;
  public isOffline:boolean = false;
  public loginUserDetail = {
      login_user_name:'John Doe',
      designation:'Captain',
      profileImage:'assets/img/user-placeholder.svg',
      role: ''
  };

   constructor(
      private readonly ngZone: NgZone,
      private readonly _storageService: StorageService,
      private readonly platform: Platform,
      private readonly menu: MenuController,
      private readonly router: Router,
      private readonly titleService: Title, 
      private readonly crewSelfService: CrewSelfService, 
      private readonly authApiService: AuthApiService,
      private readonly authService: AuthService,
      private readonly userApiService: UserApiService,
      private readonly modalController: ModalController,
    ) {  
      
      this.getRouter();
      this.device = this.getDevive();
      this.crewSelfService.isDesktopSet();
      this.crewSelfService.setCurrentPlatform();
      this.subscription = this.crewSelfService.isDesktopGet().subscribe((event) => {
        this.isDesktop = event;
      })
    }
    isAccessGranted():boolean{
      return this._storageService.getDataFromStorage("access_token")?true:false;
    }
  
    getRouter() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.parseRoute(this.router.routerState.snapshot.root); 
          let splitUrl = event.url.split('/');
          let url = (splitUrl[1]) ? splitUrl[1] : this.activeMenuItem; 
          this.activeMenuItem = url;
          this.loadMenuOption(url);
          this.closeMenu();
          if(Capacitor.isNativePlatform()) {
            this.isNative = true;
            this.nativeClass = 'native';
          }
        } 
      })
    }

  async ngOnInit() {
    this.isSkelton = true;

    // refresh the token on navigation 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.refreshAccessToken();
      }
    });    
    
    // check is offline
    const status = await Network.getStatus();
    console.log('AppComponent Network default', status, this.authService.isOnlineSubject.value);
    this.authService.isOnlineSubject.next(status.connected);
    console.log('AppComponent Network updated', status, this.authService.isOnlineSubject.value);
    if (!this.authService.isOnlineSubject.value) {  
      this.isOffline = true;
    }

    // Add network status change listener
    Network.addListener('networkStatusChange', async (status:any) => {
      console.log('AppComponent networkStatusChange listner', {status:status.connected, b2cAuthCode:this.authService.b2cAuthCodeBySignalR, isLoginInProgress:this.authService.isLoginInProgress});
      this.ngZone.run(() => {
        this.authService.isOnlineSubject.next(status.connected);
        if (!this.authService.isOnlineSubject.value) {
          this.isOffline = true;
        } else {
          this.isOffline = false;  

          if(this.authService.b2cAuthCodeBySignalR){
            console.log('AppComponent networkStatusChange acquireB2CAccessToken called.', status);
            this.authService.acquireB2CAccessToken(this.authService.b2cAuthCodeBySignalR)
          } else if(!this.authService.isLoginInProgress){
            console.log('AppComponent networkStatusChange authGuard called.', status);
            this.authService.authGuard();
          }
        }
      });
    });
  }

  getDevive() {
    if ((this.platform.is('ios') || this.platform.is('iphone'))) {
      return "ios";
    }
    return this.platform.is('android') ? 'android' : 'web';
  }

  refreshAccessToken() {
    const refreshToken: any = this._storageService.getDataFromStorage('refresh_token');
    if (this.authService.isOnlineSubject.value && refreshToken) {
      this.authApiService.b2cRefreshTokenApi(refreshToken).subscribe({
        next: (response:any) => {
          this._storageService.putDataInStorage(
            'access_token',
            response.access_token
          );
          this._storageService.putDataInStorage(
            'refresh_token',
            response.refresh_token
          );
          this.getApprovedUserDetail();
        },
        error: (err: any) => {
          this.authService.signOut();
        },
      });
    }
  }

  collapsed(data: any) {
    this.isCollapsed = data;
  }

  loadMenuOption(navigationUrl:string){
    
    this.bottomMenuOptions = [
      {
        "iconName": "icon/home",
        "iconType":"svg",
        "displayName": "Home",
        "selected": this.menuselected(navigationUrl,'home'),
        "path": 'home' 
      },
      {
       "iconName": "icon/files",
        "iconType":"svg",
        "displayName": "Documents",
        "selected": this.menuselected(navigationUrl,'documents'),
        "path": 'documents' 
      },
      {
        "iconName": "icon/dollar-sign",
        "iconType":"svg",
        "displayName": "Payroll & expenses",
        "selected": this.menuselected(navigationUrl,'payroll-expenses'),
        "path": 'payroll-expenses'
      },
      {
        "iconName": "icon/user",
        "iconType":"svg",
        "displayName": "Profile",
        "selected": this.menuselected(navigationUrl,'profile'),
        "path": 'profile'
      }
    ]
  }

  public menuselected(select:string,menu:string){
    if(select?.includes(menu)){
      return true;
    }
    return false;
  }

  public getApprovedUserDetail() {
    this.userApiService.fetchUserApprovedDetail()
      .subscribe({
          next: (response) => {
            if(response.status === 'success') {
              this.isSkelton = false;
              this.loginUserDetail.login_user_name = response.data.FIRST_NAME + ' '  + response.data.LAST_NAME;
              this.loginUserDetail.designation = response.data.RANK_DESCRIPTION;
              this.loginUserDetail.role = response.data.RANK_CODE;
              this.loginUserDetail.profileImage =  response.data.PROFILE_IMAGE_URL || 'assets/img/Stolt-Satsuki.png';
              this.crewSelfService.isFillipinoSet(response.data.POOL_CODE);
              this.crewSelfService.isPoolCodeSet(response.data.POOL_CODE);
              this.crewSelfService.isSetActiveUser(response.data);
            }
          },
          error: (err: any) => {
            console.error('Error user approved detail:', err);
          }
    });
  }

  private parseRoute(node: ActivatedRouteSnapshot) {
    this.titleService.setTitle('Crew Self Service 2.0 - ' + node.data['title']);
    this.pageTitle = node.data['title'];
    this.sidebar = (node?.data['sidebar']) ? true : false;

    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  public closeMenu(){
    this.menu.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    Network.removeAllListeners();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.crewSelfService.isDesktopSet();
    this.crewSelfService.setCurrentPlatform();
  }

}
