<div class="theme-container open-reporting">
  <div class="theme-container-inner">
    <div class=" breadcrumb-main">
      <h1 class="header-h1  fw-700 color__primary">Open reporting</h1>
    </div>
    <div class="ov-flow border__radius8 tab-page">
      <div class="bg-colour">
        <img alt="ST-logo" src="../assets/img/Open-reporting.jpg" />
      </div>
    </div>
  </div>
</div>
  