import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { AlertController, Platform } from '@ionic/angular';
import * as signalR from '@microsoft/signalr';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class CrewSelfService {
  private signOutSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authCode: string = '';
  public connectionError: string = '';
  public connectionId: string = '';
  public loginLink: string = '';
  public logoutLink: string = '';
  public isNative: boolean = Capacitor.isNativePlatform();
  public srHubconnection: signalR.HubConnection | undefined;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private platform: Platform,
    private alertController: AlertController,
    private _storageService: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    Browser.addListener('browserFinished', () => {      
      if(this.srHubconnection){
        this.srHubconnection.stop().then(() => {
          this.authGuard();
          console.log('Connection closed');
        });
      }
    });
  }

  getSignOutPopup() {
    return this.signOutSubject.asObservable();
  }

  setSignOutPopup(bool: boolean) {
    this.signOutSubject.next(bool);
  }

  async showCopyAlert(content: string) {
    const alert = await this.alertController.create({
      header: 'Copy Content',
      message: `<strong>${content}</strong>`,
      buttons: [
        {
          text: 'Copy',
          handler: () => {
            this.copyToClipboard(content);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
  }

  copyToClipboard(content: string) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }

  async getAccessToken(code: string) {
    const code_verifier = this._storageService.getDataFromStorage('code_verifier');
    if (!code_verifier) {
      console.error('Code verifier not found');
      return;
    } else {
      // clear code_verifier from local storage
      this._storageService.removeDataFromStorage('code_verifier');
    }

    this.apiService.b2cTokenApi(code, code_verifier).subscribe({
      next: (response) => {
        console.log('Access Token:', response);
        if (response.status === 200) {
          // get user name from token
          const token_data = jwtDecode(response.data.id_token) as {name: string};
          
          // save logged in user name in local storage
          this._storageService.putDataInStorage(
            'login_user_name',
            token_data.name
          );

          // save access token in local storage
          this._storageService.putDataInStorage(
            'access_token',
            response.data.id_token
          );

          // navigate to home page after getting the access_token
          this.router.navigate(['home']);
        } else {
          console.error('Error fetching access_token:', response);
        }
      },
      error: (err: any) => {
        console.error('Error fetching access_token:', err);
      },
    });
  }

  redirectToB2c(code_challange: string, logout?: boolean) {
    let state:any = {platform:'native'};
    if(logout) {
      state['code_challange'] = code_challange;
    }
    console.log('State:', state);

    this.apiService.signalTokenApi(uuidv4(), state).subscribe({
      next: (response) => {        
        const state = response.data.state;
        const signaRHubEndpoint = response.data.connection.url;
        const signalRToken = response.data.connection.accessToken;
        if(signalRToken && signaRHubEndpoint) {
          console.log('SignalR Token:', signalRToken, state);   
          this.srHubconnection = new signalR.HubConnectionBuilder()
            .withUrl(signaRHubEndpoint, {
              skipNegotiation: true,
              transport: signalR.HttpTransportType.WebSockets,
              accessTokenFactory: () => signalRToken
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

          const start = async () => {
            try {
              if(this.srHubconnection){
                await this.srHubconnection.start();
                console.log('SignalR Connected.', this.srHubconnection);
                if(logout) {
                  Browser.open({ url: this.getB2CLogoutUrl(state) });
                } else {
                  Browser.open({ url: this.getB2CLoginUrl(state, code_challange) });
                }
              }
            } catch (err) {
              this.connectionError = err as string;
              setTimeout(start, 5000);
            }
          };

          this.srHubconnection.on('newAuthCode', (code:string, return_state:string) => {
            console.log('New Auth Code:', code);
            console.log('State:', state, return_state);
            if (code && state && return_state === state) {
              this.authCode = code;
              Browser.close();
              this.getAccessToken(code);
              if(this.srHubconnection){
                this.srHubconnection.stop().then(() => {
                  console.log('Connection closed');
                });
              }
            }
          });
          start();
        } else {
          console.error('SignalR Token or Endpoint not found');
        }
      },
      error: (err: any) => {
        console.error('Error fetching SignalR token:', err);
      },      
    });
  }

  authGuard() {
    let _reqToken: any = this._storageService.getDataFromStorage('access_token');
    console.log('called=====', _reqToken);
    if (!_reqToken && _reqToken == null && _reqToken == undefined) {      
      // generate a random state and save it in local storage
      this.generateCodeVerifierAndChallenge().then((response) => {        
        if (this.isNative) {
          // move to auth component and then redirect to B2C login page
          this.router.navigate(['auth']);

          // save the code_verifier in local storage
          this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

          // redirect to B2C login handler
          this.redirectToB2c(response.codeChallenge);
        } else {        
          this.apiService.webTokenApi(uuidv4(), {platform:'web'}).subscribe({
            next: (res) => {    
              // save the code_verifier in local storage
              this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

              // redirect to B2C login page
              window.location.href = this.getB2CLoginUrl(res.data.state, response.codeChallenge);   
            },
            error: (err: any) => {
              console.error('Error fetching web state:', err);
            },      
          });
        }
      });
      
      return false;
    }
    return true;
  }

  getB2CLoginUrl(state: string, code_challenge: string) {
    return (
      environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/authorize' +
      `?client_id=${environment.B2C_CLIENT_ID}` +
      `&response_type=code` +
      `&redirect_uri=${environment.B2C_REDIRECT_URI}` +
      `&response_mode=query` +
      `&scope=${environment.B2C_SCOPE}` +
      `&code_challenge=${code_challenge}` +
      `&state=${state}` +
      `&code_challenge_method=S256`
    );
  }

  getB2CLogoutUrl(state: string) { 
    return (
      environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/logout' +
      `?post_logout_redirect_uri=${environment.B2C_LOGOUT_REDIRECT_URI}` +
      `&state=${state}`
    );
  }

  signOut() {
    this._storageService.removeDataFromStorage('login_user_name');
    this._storageService.removeDataFromStorage('access_token');
    
    this.generateCodeVerifierAndChallenge().then((response) => {  
      if (this.isNative) {
        // save the code_verifier in local storage
        this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

        // redirect to B2C logout handler
        this.redirectToB2c(response.codeChallenge, true);
      } else {      
        this.apiService.webTokenApi(uuidv4(), {platform:'web', code_challange: response.codeChallenge}).subscribe({
          next: (res) => {
            // save the code_verifier in local storage
            this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

            // redirect to B2C logout page
            window.location.href = this.getB2CLogoutUrl(res.data.state);    
          },
          error: (err: any) => {
            console.error('Error fetching web state:', err);
          },      
        });
      }
    });
  }

  randomString(length:number=40, chars:string='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  async generateCodeVerifierAndChallenge() {
    // Generate a random code_verifier of 128 characters
    const codeVerifier = this.generateRandomString(128); 

    // Generate the code_challenge from the code_verifier 
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);  

    return { codeVerifier, codeChallenge };
  }

  // Helper function to generate a random string of a given length  
  generateRandomString(length: number) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let randomString = '';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
      randomString += charset[randomValues[i] % charset.length];
    }
    return randomString;
  }
  
  // Helper function to generate a code_challenge from a code_verifier  
  async generateCodeChallenge(codeVerifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return this.base64UrlEncode(new Uint8Array(digest));
  }
  
  // Helper function to Base64 URL-encode an array of bytes
  base64UrlEncode(buffer: Uint8Array) {
    return btoa(String.fromCharCode(...buffer))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }
}
