import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import * as signalR from '@microsoft/signalr';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly signOutSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authCode: string = '';
  public connectionError: string = '';
  public connectionId: string = '';
  public loginLink: string = '';
  public logoutLink: string = '';
  public isNative: boolean = Capacitor.isNativePlatform();
  public srHubconnection: signalR.HubConnection | undefined;
  
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly router: Router,
    private readonly authApiService: AuthApiService,
    private readonly alertController: AlertController,
    private readonly _storageService: StorageService,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {
    this.initializeBrowserListener();
  }  

  initializeBrowserListener(){
    Browser.addListener('browserFinished', () => {      
      if(this.srHubconnection){
        this.srHubconnection.stop().then(() => {
          this.authGuard();
        });
      }
    });
  }

  getSignOutPopup() {
    return this.signOutSubject.asObservable();
  }

  setSignOutPopup(bool: boolean) {
    this.signOutSubject.next(bool);
  }

  async showCopyAlert(content: string) {
    const alert = await this.alertController.create({
      header: 'Copy Content',
      message: `<strong>${content}</strong>`,
      buttons: [
        {
          text: 'Copy',
          handler: () => {
            this.copyToClipboard(content);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
  }

  copyToClipboard(content: string) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }

  async acquireB2CAccessToken(code: string) {
    const code_verifier = this._storageService.getDataFromStorage('code_verifier');
    if (!code_verifier) {
      return;
    } else {
      // clear code_verifier from local storage
      this._storageService.removeDataFromStorage('code_verifier');
    }

    this.authApiService.b2cTokenApi(code, code_verifier).subscribe({
      next: (response) => {

          // save access and refresh token in local storage
          this._storageService.putDataInStorage(
            'access_token',
            response.access_token
          );
          this._storageService.putDataInStorage(
            'refresh_token',
            response.refresh_token
          );
          if(this.isNative){
            Browser.close();
          }

          // navigate to home page after getting the access_token
          this.router.navigate(['home']);
        
      },
      error: (err: any) => {
        console.error('Error fetching access_token:', err);
      },
    });
  }

  redirectToB2c(code_challange: string, logout?: boolean) {
    const state = this.initializeStateForSignalRConnection(code_challange, logout);

    this.authApiService.signalTokenApi(uuidv4(), state).subscribe({
      next: (response:any) => {    
        const state = response.data.state;
        const signaRHubEndpoint = response.data.connection.url;
        const signalRToken = response.data.connection.accessToken;
        if(signalRToken && signaRHubEndpoint) {
          this.srHubconnection = new signalR.HubConnectionBuilder()
            .withUrl(signaRHubEndpoint, {
              skipNegotiation: true,
              transport: signalR.HttpTransportType.WebSockets,
              accessTokenFactory: () => signalRToken
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

          

          this.registerSignalRListener(state);
          this.startSignalRHubConnection(state, code_challange, logout);
        } else {
          console.error('SignalR Token or Endpoint not found');
        }
      },
      error: (err: any) => {
        console.error('Error fetching SignalR token:');
      },      
    });
  }

  registerSignalRListener(state:any){
    if(this.srHubconnection !== undefined){
      this.srHubconnection.on('newAuthCode', (code:string, return_state:string) => {
        if (code && state && return_state === state) {
          this.authCode = code;
          this.acquireB2CAccessToken(code);
          if(this.srHubconnection){
            this.srHubconnection.stop().then(() => {
              console.log('Connection closed');
            });
          }
        }
      });
    }
  }

  async startSignalRHubConnection(state:any, code_challange:string, logout?: boolean){
    try {
      if(this.srHubconnection){
        await this.srHubconnection.start();
        if(logout) {
          Browser.open({ url: this.getB2CLogoutUrl(state) });
        } else {
          Browser.open({ url: this.getB2CLoginUrl(state, code_challange) });
        }
      }
    } catch (err) {
      this.connectionError = err as string;
      setTimeout(this.startSignalRHubConnection, 5000);
    }
  }
  
  initializeStateForSignalRConnection(code_challange: string, logout?: boolean): any {
    let state:any = {platform:'native'};
    if (logout) {
      state['code_challange'] = code_challange;
    }
    return state;
  }  

  authGuard() {
    let _reqToken: any = this._storageService.getDataFromStorage('access_token');
    if (!_reqToken && _reqToken == null && _reqToken == undefined) {      
      // generate a random state and save it in local storage
      this.generateCodeVerifierAndChallenge().then((response) => {        
        if (this.isNative) {
          // move to auth component and then redirect to B2C login page

          // save the code_verifier in local storage
          this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

          // redirect to B2C login handler
          this.redirectToB2c(response.codeChallenge);
          this.router.navigate(['auth']);
        } else {        
          this.authApiService.webTokenApi(uuidv4(), {platform:'web'}).subscribe({
            next: (res:any) => {    
              // save the code_verifier in local storage
              this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

              // redirect to B2C login page
              window.location.href = this.getB2CLoginUrl(res.state, response.codeChallenge);   
            },
            error: (err: any) => {
              console.error('Error fetching web state:', err);
            },      
          });
        }
      });
      
      return false;
    }
    return true;
  }

  getB2CLoginUrl(state: string, code_challenge: string) {
    return (
      environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/authorize' +
      `?client_id=${environment.B2C_CLIENT_ID}` +
      `&response_type=code` +
      `&redirect_uri=${environment.B2C_REDIRECT_URI}` +
      `&response_mode=query` +
      `&scope=${environment.B2C_USER_LOGIN_SCOPE}` +
      `&code_challenge=${code_challenge}` +
      `&state=${state}` +
      `&code_challenge_method=S256`
    );
  }

  getB2CLogoutUrl(state: string) { 
    return (
      environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/logout' +
      `?post_logout_redirect_uri=${environment.B2C_LOGOUT_REDIRECT_URI}` +
      `&state=${state}`
    );
  }

  signOut() {
    this._storageService.clearStorage();
    this.generateCodeVerifierAndChallenge().then((response) => {  
      if (this.isNative) {

        // save the code_verifier in local storage
        this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

        // redirect to B2C logout handler
        this.redirectToB2c(response.codeChallenge, true);
        this.router.navigate(['auth']);

      } else {    
        this.authApiService.webTokenApi(uuidv4(), {platform:'web', code_challange: response.codeChallenge}).subscribe({
          next: (res:any) => {
            // save the code_verifier in local storage
            this._storageService.putDataInStorage('code_verifier', response.codeVerifier);

            // redirect to B2C logout page
            window.location.href = this.getB2CLogoutUrl(res.state);    
          },
          error: (err: any) => {
            console.error('Error fetching web state:', err);
          },      
        });
      }
    });
  }

  async generateCodeVerifierAndChallenge() {
    // Generate a random code_verifier of 128 characters
    const codeVerifier = this.generateRandomString(128); 

    // Generate the code_challenge from the code_verifier 
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);  

    return { codeVerifier, codeChallenge };
  }

  // Helper function to generate a random string of a given length  
  generateRandomString(length: number) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let randomString = '';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
      randomString += charset[randomValues[i] % charset.length];
    }
    return randomString;
  }
  
  // Helper function to generate a code_challenge from a code_verifier  
  async generateCodeChallenge(codeVerifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return this.base64UrlEncode(new Uint8Array(digest));
  }
  
  // Helper function to Base64 URL-encode an array of bytes
  base64UrlEncode(buffer: Uint8Array) {
    return btoa(String.fromCharCode(...buffer))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  refreshAccessToken(): Observable<string> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.asObservable() as any;
    } else {
      this.refreshTokenInProgress = true;
      const refresh_token:any = this._storageService.getDataFromStorage('refresh_token');
      return this.authApiService.b2cRefreshTokenApi(refresh_token).pipe(
        tap((response:any) => {
          console.log("Access token refreshed.");
          this._storageService.putDataInStorage(
            'access_token',
            response.access_token
          );
          this._storageService.putDataInStorage(
            'refresh_token',
            response.refresh_token
          );
          this.refreshTokenInProgress = false;
        }),
        map(response => response.access_token),
        catchError(err => {
          return throwError(() => err);
        })
      );
    }
  }

  isTokenExpired(): boolean {
    const token = this._storageService.getDataFromStorage('access_token');
    if (!token) return true;

    let expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    console.log("Access token expiring in " + (expiry - (Math.floor(new Date().getTime() / 1000))) + " seconds.");
    return (Math.floor(new Date().getTime() / 1000)) >= expiry;
  }
}

