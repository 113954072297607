import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { API_ENDPOINTS } from './endpoints'; // Import the constant file
import { environment } from '../../environments/environment';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = 'http://localhost:8000'; // Your base API URL

  constructor(private http: HttpClient) {}

  // Set default headers (can be modified if needed)
  private setHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': `Bearer ${this.getToken()}`,
      'Access-Control-Allow-Origin':'*'
    });
  }

  // Dummy function to get token, replace with real implementation
  private getToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  // GET Request
  get<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${endpoint}`, { headers: this.setHeaders() });
  }

  // POST Request
  post(endpoint: string, data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}${endpoint}`, data, { headers: this.setHeaders() });
  }

  // PUT Request
  put<T>(endpoint: string, data: any): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}${endpoint}`, data, { headers: this.setHeaders() });
  }

  // DELETE Request
  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}${endpoint}`, { headers: this.setHeaders() });
  }

  // Example methods for specific endpoints
  login(data: { username: string; password: string }): Observable<any> {
    return this.post(API_ENDPOINTS.AUTH.LOGIN, data);
  }

  b2cTokenApi(code: string, code_verifier: string) {
    const options: HttpOptions = {
      url: environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/token' +
      `?client_id=${environment.B2C_CLIENT_ID}` +
      `&grant_type=authorization_code` +
      `&redirect_uri=${environment.B2C_REDIRECT_URI}` +
      `&scope=${environment.B2C_SCOPE}` +
      `&code_verifier=${code_verifier}` +
      `&code=${code}`
    };
    return from(CapacitorHttp.post(options));
  }

  signalTokenApi(userId: string, data: any) {
    const options: HttpOptions = {
      url: environment.AUTH_API_URL + '/negotiate',
      headers: {
        'x-ms-client-principal-id': userId
      },
      data
    };
    return from(CapacitorHttp.post(options));
  }

  webTokenApi(userId: string, data: any) {
    const options: HttpOptions = {
      url: environment.AUTH_API_URL + '/web-negotiate',
      headers: {
        'x-ms-client-principal-id': userId
      },
      data
    };
    return from(CapacitorHttp.post(options));
  }
}
 
