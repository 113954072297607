import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  loginState: string = '';
  @Input() title: string = 'Login error';
  @Input() message: string = 'Something went wrong, please try logging in again.';
  @Input() hideLoginAgainLink: boolean = false; 
  
  constructor(
    private authService: AuthService,
    private modalController: ModalController,
  ) { }

  async loginAgain() {
    if (this.loginState !== '') {
      window.location.href = this.authService.getB2CLogoutUrl(this.loginState);
    } else {
      await this.modalController.dismiss();
      this.authService.signOut();
    }
  }
}
