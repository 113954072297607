<ion-card-content (click) = "rowClick(data)">
  <div class="type d-flex">
    <div class="left">
        <snl-icon class='df-c'  iconType="svg" [icon]="getNotificationTitle(data).icon"></snl-icon>
        <span class="name caption color__secondary">{{getNotificationTitle(data).title}}</span>
    </div>
  <div class="right"><span class="caption color__secondary">{{data?.updatedAt | niceDateFormat}}</span> </div>
  </div>
  <div class="d-flex gap-8">
    <snl-badge *ngIf="!data?.IS_READ"></snl-badge>
    <div class="color__primary" [ngClass]="!data?.IS_READ ? 'section-title' : 'body-b1 color__secondary' " [title]="data?.DESCRIPTION">{{data?.DESCRIPTION}}</div>
  </div>
</ion-card-content>