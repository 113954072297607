import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CrewSelfService } from 'src/app/shared/services/crew-self.service';

@Component({
  selector: 'app-corporate-safety',
  templateUrl: './corporate-safety.component.html',
  styleUrls: ['./corporate-safety.component.scss'],
})
export class CorporateSafetyComponent  implements OnInit {
  tabs = [
    { key: 'Open Reporting', value: 'Open Reporting'},
    { key: 'Stop Work Authority', value: 'Stop Work Authority'},
 
  ];
  isDesktop:boolean= true;
  public subscription: Subscription;
  public selectedTab:string ='Open Reporting';

  constructor( private readonly crewSelfService: CrewSelfService){
    this.subscription = this.crewSelfService.isDesktopGet().subscribe((event) => {
      this.isDesktop = event;
    })
  }

  ngOnInit() {}

  clickOnPageTab(tab:string){
    this.selectedTab = tab;
  }
}
