import { Component, Input } from '@angular/core';
import { NotificationApiService } from 'src/app/shared/services/notification-api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-notifications-card',
  templateUrl: './notifications-card.component.html',
  styleUrls: ['./notifications-card.component.scss'],
})
export class NotificationsCardComponent{

  @Input() data:any;

  constructor(
    private readonly notificationApiService: NotificationApiService,
    private readonly notificationService: NotificationService,
  ) { }

  

  getNotificationTitle(data:any){
    return this.notificationService.notificationTitle(data.SECTION);
  }

  rowClick(data:any){
    if(!data.IS_READ){
      this.updateNotification(data);
    }
    else{
      this.notificationService.notificationAction(data);
    }
  }

  updateNotification(data:any){
    this.notificationApiService.updateNotification(data._id).subscribe((res:any)=>{
      this.notificationService.notificationAction(data);
    });
  }


}
