import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../admin/shared/services/auth.service';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    const state: string = this.route.snapshot.queryParamMap.get('state') || '';
    const code: string = this.route.snapshot.queryParamMap.get('code') || '';
    const logout: string = this.route.snapshot.queryParamMap.get('logout') || '';

    if (state && (code || logout)) {
      interface StateData {
        id: string;
        platform: 'web' | 'native';
        [code_challange: string]: any;
      }

      const state_data = jwtDecode<StateData>(state);
      const platform = state_data.platform;
      if(code) {
        if (platform === 'native') {          
          const options: HttpOptions = {
            url: `${environment.AUTH_API_URL}/broadcast`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: { state:state, code, userId: state_data.id},
          };
          CapacitorHttp.post(options)
            .then((response) => {
            })
            .catch((error) => {
              console.error('Error', error);
            });
        } else {
          this.authService.acquireB2CAccessToken(code);
        }
      } else {
        window.location.href = this.authService.getB2CLoginUrl(state, state_data['code_challange']);
      }
    } else {
      console.error('Invalid request');
    }
  }
}

