<div class="theme-container corporate-page">
  <div class="theme-container-inner">
    <div class="breadcrumb-main">
      <h1 class="header-h1 color__primary">Corporate safety</h1>
    </div>
     <div class="tab-page card-mrgn-b-32 border__radius8" >
    <div class="tab-section d-flex-column">
      <div class='tab horizontal-scroll' #tabScroll>
        <snl-page-tab *ngFor="let tab of tabs; let i=index" [isSelected]="(selectedTab == tab.key) ? true : false" (onPageTabClick)="clickOnPageTab(tab.key)">{{tab.value}}</snl-page-tab>
      </div>
      <div class="tab-content">
        <app-tab-content [isDesktop]="isDesktop"  *ngIf="selectedTab" [selectedTab]="selectedTab"></app-tab-content>
      </div>
    </div>
    </div>
  </div>
</div>