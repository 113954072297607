  export const environment = {
  production: false,
  navType: 'dev',
  b2cToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcG4iOiIzNzM5In0.iyxQTVH_vS1LO03N2M4egQYmEAJn4VT8vJeuwcguW1w",
  API_URL: 'https://app-st-css-dev-we-member.azurewebsites.net/api/v1/',
  INTRANSIT_ENCRYPTION_ENABLED: false,

  AUTH_API_URL: 'https://func-st-css-dev-we.azurewebsites.net/api',
  B2C_CLIENT_ID: '042c4399-bcf8-4a76-9035-a76c3faa7f6c',
  B2C_REDIRECT_URI: 'https://css.stoltdev.com/auth',
  B2C_LOGOUT_REDIRECT_URI: 'https://css.stoltdev.com/auth?logout=true',
  B2C_LOGIN_AUTHORITY:'https://stoltdevb2c.b2clogin.com/stoltdevb2c.onmicrosoft.com/B2C_1A_STCSS_SIGNUP_SIGNIN',
  B2C_SCOPE: 'openid profile offline_access',
  B2C_CODE_VERIFIER:"pPDBrZr-twVj09q0FitBUcSlta1smpvVlkraIgnRQlXC54k8iGdUzPEo02Glvlg6TdAyq-hbSq9fwkTGPp-zYpNV3N443nJSW3sGlfs--qmWCTNZaivoy3m5yWD0gPog",
  B2C_CODE_CHALLANGE: "pBwVF2rDKRHudZsXAHPhFfNELmq5gXQ3uPI9DMs7hss",
};
 