import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrewSelfService } from '../services/crew-self.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StoltApiService {
  
  constructor(private readonly http: HttpClient,private readonly CrewSelfService: CrewSelfService) { }

  private setHeaders() {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
    };
  }

  private setHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': `Bearer ${this.getToken()}`
    });
  }

  getToken() {
    return environment.b2cToken || '';
  }

  sendGetRequest(endpoint: string) {
    const options: HttpOptions = {
      url: `${environment.API_URL}${endpoint}`,
      headers: this.setHeaders(),
    };
    return from(CapacitorHttp.get(options));
  }

  public encriptionData(data:any) {
    return (environment.INTRANSIT_ENCRYPTION_ENABLED) ?  this.CrewSelfService.encryptData(data) : data;
  }

  sendPostRequest(endpoint: string,data:any) {
    const options: HttpOptions = {
      url: `${environment.API_URL}${endpoint}`,
      headers: this.setHeaders(),
      data: this.encriptionData(data)
    };
    return from(CapacitorHttp.post(options));
  }

  sendDeleteRequest(endpoint: string) {
    return  this.http.delete(`${environment.API_URL}${endpoint}`,{ headers: this.setHeader() })
  }

  uploadImage(imageBase64: any,endpoint:string, name:string,type?: string, ): Observable<any> {
    let imageBlob:any;
    if(type==="image"){
      imageBlob = this.dataURItoBlob(imageBase64 as string);
    }
    else{
      imageBlob = imageBase64
    }
    const formData = new FormData();
    formData.append('file', imageBlob, name); // You can change the file name
    return  this.http.post(`${environment.API_URL}${endpoint}`, formData,{headers: new HttpHeaders({})})
  }

  // Helper function to convert base64 to Blob
  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }


}
