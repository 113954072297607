import { Component, EventEmitter, Input, OnInit, SimpleChanges, Output, Inject, Renderer2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalController, Platform } from '@ionic/angular';
import { ContentModalComponent } from './content-modal/content-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent  implements OnInit {

  @Input() options: any;
  @Input() isError: boolean = false;
  @Input() field: string = '';
  @Input() multiple = false;
  @Input() label: string = '';
  @Input() type = 'checkout';
  @Input() buttonPrimary = 'Apply';
  @Input() buttonSecondary = 'Clear';
  @Input() preSelectedItems: any;
  @Output() selectedValues = new EventEmitter();
  @Output() resetDropdown = new EventEmitter();
  isDesktop: boolean = false;
  prevValue:any;
  preSelect:string[] = [];
  preSelectNative:string= '';
  reset:boolean = true;
  iOpenDropdown: boolean = false;
  dropdownValue:string = '';
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly modalCtrl: ModalController,
    private readonly platform: Platform,
    private readonly fb: FormBuilder,
  ) {

  }
  
  ngOnInit() {

    this.platform.ready().then(() => {
      this.isDesktop = true;
      if(this.platform.width() < 768){
        this.isDesktop =  false;
      }
    });
    const selectedOption = this.options.find((item: { id: any; }) => item.id === this.preSelectedItems);
    this.preSelect = selectedOption ? [selectedOption] : [];
    this.preSelectNative = selectedOption ? selectedOption.name : '';
    if(this.platform.width() < 768){
      this.dropdownValue = this.preSelectNative;
    }
    
  }



  ngOnChanges(changes: SimpleChanges) {
    for (let change in changes) {
       this.prevValue = changes['preSelectedItems']?.previousValue;
      if(change == "preSelectedItems" && changes['preSelectedItems'].currentValue){
        const selectedOption = this.options.find((item: { id: any; }) => item.id === this.preSelectedItems);
        this.preSelect = selectedOption ? [selectedOption] : [];
        this.preSelectNative = selectedOption ? selectedOption.name : '';
        if(this.platform.width() < 768){
          this.dropdownValue = this.preSelectNative;
        }  
      }
    } 
 }

  selectedValuesChange(event:any){
    this.selectedValues.emit(event.selectedRecords);
  }

  resetDropdownChange(event:any){
    this.preSelect = [];
    this.preSelectNative = '';
    this.resetDropdown.emit([]);
  }

  onDropdownClick() {
      this.renderer.addClass(document.body, 'open-dropdown');
      if(!this.isDesktop){
        this.openCategoryModal();
      }
  }

  async openCategoryModal() {
    if(!this.iOpenDropdown){
      this.iOpenDropdown = true;
      await this.modalCtrl.create({
        component: ContentModalComponent,
        breakpoints: [0, 1],
        initialBreakpoint:1,
        cssClass: 'filter-modal',
        componentProps: { 
          options: this.options,
          label: this.label,
          buttonPrimary: this.buttonPrimary,
          buttonSecondary: this.buttonSecondary,
          preSelectedItems: this.preSelectedItems
        }
      }).then(modal => {
          modal.present();
          modal.onDidDismiss().then((data: any) => {
            this.iOpenDropdown = false;
            if(data.data.event === 'clear'){
              this.preSelect = [];
              this.preSelectNative = '';
              this.dropdownValue = '';
              this.reset = false;
              setTimeout(() => {
                this.reset = true;
              }, 1);
              this.resetDropdown.emit(''); 
            }
            else if(data.data.event === 'apply'){
              this.preSelectNative = data.data.selectedValue[0].name;
              this.dropdownValue = this.preSelectNative;
              this.selectedValues.emit(data.data.selectedValue[0]);
            }
        });
      });
    }
   
  }

}