import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { CrewSelfService } from './crew-self.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false; // Flag to prevent multiple refreshes
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private pendingRequests: Array<Function> = []; // Queue of pending requests  
  private isEncryptionEnabled = environment.INTRANSIT_ENCRYPTION_ENABLED;

  constructor(
    private authService: AuthService, 
    private storageService: StorageService,
    private crewSelfService: CrewSelfService, 
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = req.headers.has('skip');    

    // Clone the request to add the token to it
    if(skipIntercept){
        req = req.clone({
            headers: req.headers.delete('skip')
        });
    } else {
        // encrypt the request body if encryption is enabled    
        if(this.isEncryptionEnabled) {
            req = this.crewSelfService.encryptHttpRequestBody(req);
        }

        req = this.addAuthHeader(req);
    }

    return next.handle(req).pipe(
        map((event: any) => { return this.httpResponseHandler(event)}),
        catchError((err: HttpErrorResponse) => {return this.httpResponseErrorHandler(err, req, next, skipIntercept)})
    );
  }

  private httpResponseHandler(event:any){
    if (event instanceof HttpResponse) {
      if (this.isEncryptionEnabled && event.body?.data) {
        try {
          const decryptedBody = this.crewSelfService.decryptData(event.body.data);
          event.body.data = decryptedBody;
        } catch (error) {
          console.log('AuthInterceptor: Decryption failed:', error);
        }
      }
      return event.clone({ body: event.body });
    }
    return event;
  }

  private httpResponseErrorHandler(error:HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler, skipIntercept:boolean){    
    if (error.status === 401 && !skipIntercept && !this.authService.isLoginInProgress) {
      return this.handle401Error(req, next);
    } else {
      return throwError(() => error);
    }
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      console.log("AuthInterceptor: Token expired, refreshing!");
      return this.authService.refreshAccessToken().pipe(
        switchMap((newToken: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken);

          // Retry the pending requests
          this.pendingRequests.forEach((request) => request());
          this.pendingRequests = [];

          return next.handle(this.addAuthHeader(req)).pipe(
            map((event: any) => { return this.httpResponseHandler(event)})
          );
        }),
        catchError((error) => {
          console.log("AuthInterceptor: Unable to refresh the access token, logging out!", error);
          this.isRefreshing = false;
          this.pendingRequests = [];
          this.authService.signOut();
          return throwError(() => error);
        })
      );
    } else {
      // Queue the request if token is being refreshed
      return new Observable<HttpEvent<any>>((observer) => {
        this.pendingRequests.push(() => {
          next.handle(this.addAuthHeader(req)).pipe(
            map((event: any) => { return this.httpResponseHandler(event)})
          ).subscribe(observer);
        });
      });
    }
  }

  private addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    let token = this.storageService.getDataFromStorage('access_token');
    if(this.authService.isTokenExpired())
        token = null;
    
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}