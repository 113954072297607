import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SnlComponentsModule } from 'snl-complib';
import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { AuthGuard } from './auth/_guards';
import { DatePipe } from '@angular/common';
import { authInterceptor } from './admin/shared/services/http-client-headers-interceptor.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,SnlComponentsModule],
  providers: [
    DatePipe,
    ApiService,
      provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([authInterceptor])),
     AuthGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
