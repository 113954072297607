import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { from, Observable } from 'rxjs';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  
  constructor(private readonly http: HttpClient) { }


  b2cTokenApi(code: string, code_verifier: string): Observable<any>{
    let url  = environment.B2C_LOGIN_AUTHORITY +
    '/oauth2/v2.0/token' +
    `?client_id=${environment.B2C_CLIENT_ID}` +
    `&grant_type=authorization_code` +
    `&redirect_uri=${environment.B2C_REDIRECT_URI}` +
    `&scope=${environment.B2C_USER_TOKEN_SCOPE}` +
    `&code_verifier=${code_verifier}` +
    `&code=${code}`;
    return this.http.get(url, {headers: {skip:"true"}});
  }

  b2cRefreshTokenApi(refresh_token: string) {
    let url= environment.B2C_LOGIN_AUTHORITY +
      '/oauth2/v2.0/token' +
      `?client_id=${environment.B2C_CLIENT_ID}` +
      `&grant_type=refresh_token` +
      `&redirect_uri=${environment.B2C_REDIRECT_URI}` +
      `&scope=${environment.B2C_USER_TOKEN_SCOPE}` +
      `&refresh_token=${refresh_token}`
    return this.http.get(url, {headers: {skip:"true"}});
  }

  signalTokenApi(userId: string, data: any) {
    const options: HttpOptions = {
      url: environment.AUTH_API_URL + '/negotiate',
      headers: {
        'x-ms-client-principal-id': userId,
        'Content-Type': 'application/json',
        'skip': 'true'
      },
      data
    };
    return from(CapacitorHttp.post(options));
  }

  webTokenApi(userId: string, data: any) {
    let url =  environment.AUTH_API_URL + '/web-negotiate';
    return  this.http.post(url, data, {headers: {'x-ms-client-principal-id': userId, skip:"true"}});
  }

}
