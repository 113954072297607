import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from './admin/shared/services/storage.service';
import { AuthApiService } from './admin/shared/services/auth-api.service';
import { AuthService } from './admin/shared/services/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit{

  constructor(
    private readonly _storageService: StorageService,
    private readonly router: Router,
    private readonly authApiService: AuthApiService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(environment.ENABLE_LOGIN === true){
          this.refreshAccessToken();
        }
      }
    });
  }

  refreshAccessToken() {
    const refreshToken: any = this._storageService.getDataFromStorage('refresh_token');
    if (refreshToken) {
      this.authApiService.b2cRefreshTokenApi(refreshToken).subscribe({
        next: (response:any) => {
          this._storageService.putDataInStorage(
            'access_token',
            response.access_token
          );
          this._storageService.putDataInStorage(
            'refresh_token',
            response.refresh_token
          );
        },
        error: (err: any) => {
          this.authService.signOut();
        },
      });
    }
  }
}
