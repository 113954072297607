import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";
import { StorageService } from '../shared/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  public pageMessage: string = "Redirecting...";
  private networkSubscription!: Subscription;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly storageService: StorageService,
  ) {
    this.networkSubscription = this.authService.isOnline$.subscribe((status) => {
      console.log("AuthComponent networkSubscription", status)
      const token = this.storageService.getDataFromStorage('access_token');
      const state: string = this.route.snapshot.queryParamMap.get('state') || '';
      const code: string = this.route.snapshot.queryParamMap.get('code') || '';
      const logout: string = this.route.snapshot.queryParamMap.get('logout') || '';
      const error: string = this.route.snapshot.queryParamMap.get('error') || '';
      console.log('AuthComponent: Initial State', token, code, state, logout, error);

      // mark login in progress
      if(state && (code || logout || error))
        this.authService.isLoginInProgress = true;

      if (token) {
        console.log('AuthComponent networkSubscription', 'Unsubscibed for already logged in')
        this.networkSubscription?.unsubscribe();
      } else if (status && state && (code || logout || error)) {
        this.authMiddlewareHandler(state, code)
      }
    });
  }

  async ngOnInit() { }

  authMiddlewareHandler(state:string, code:string, retry:number=0) {
    console.log('AuthComponent authMiddlewareHandler invoked');
    
    interface StateData {
      id: string;
      platform: 'web' | 'native';
      [code_challange: string]: any;
    }

    const state_data = jwtDecode<StateData>(state);
    const platform = state_data.platform;
    if (code) {
      this.pageMessage = "Authorizing...";
      if (platform === 'native') {
        const options: HttpOptions = {
          url: `${environment.SIGNALR_WRAPPER_API_BASE_URL}/broadcast`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: { state: state, code, userId: state_data.id },
        };
        CapacitorHttp.post(options)
          .then((response) => {
            console.log('SignalRBroadcastService', response)
            if (response.status === 200) {
              if(retry < 3){
                setTimeout(()=>{
                  retry = retry + 1;
                  this.authMiddlewareHandler(state, code, retry)
                },3000)
              }
            } else{
              this.authService.showLoginErrorModal(state);
            }
          })
          .catch((error) => {
            console.log('SignalRBroadcastService', error)
            this.authService.showLoginErrorModal(state);
          });
      } else {
        console.log('AuthComponent authMiddlewareHandler for Web');        
        this.authService.acquireB2CAccessToken(code);
      }
    } else {
      console.log('AuthComponent authMiddlewareHandler for logout');
      window.location.href = this.authService.getB2CLoginUrl(state, state_data['code_challange']);
    }
  }
}