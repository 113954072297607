  export const environment = {
  production: false,
  navType: 'dev',
  b2cToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcG4iOiIzNzM5In0.iyxQTVH_vS1LO03N2M4egQYmEAJn4VT8vJeuwcguW1w",
  API_URL: 'https://api.stoltdev.com/css-crew-apis/v1/',
  INTRANSIT_ENCRYPTION_ENABLED: false,
  ENABLE_LOGIN:true,
  
  AUTH_API_URL: 'https://func-st-css-dev-we.azurewebsites.net/api',
  B2C_CLIENT_ID: '042c4399-bcf8-4a76-9035-a76c3faa7f6c',
  B2C_REDIRECT_URI: 'https://css.stoltdev.com/auth',
  B2C_LOGOUT_REDIRECT_URI: 'https://css.stoltdev.com/auth?logout=true',
  B2C_LOGIN_AUTHORITY:'https://stoltdevb2c.b2clogin.com/stoltdevb2c.onmicrosoft.com/B2C_1A_STCSS_SIGNUP_SIGNIN',
  B2C_USER_LOGIN_SCOPE: 'openid offline_access',
  B2C_USER_TOKEN_SCOPE: 'https://stoltdevb2c.onmicrosoft.com/2ff50c1b-a2cb-4404-9787-f6914d267eb9/read',
};
 