import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ButtonModule,
  RadioButtonModule,
  CheckboxModule,
  IconModule,
  LinkModule,
  TooltipModule,
  InputModule,
  PillsModule,
  AccordionModule,
  StatusModule,
  PageTabModule,
  CardModule,
  ExplodedDropdownModule,
  DatePickerModule,
  DateModule,
  ModalModule,
  NavigationModule,
  TableModule,
  DirectiveModule,
  DropdownModule,
  ProfileImageModule,
  RangeSliderModule,
  HeaderStandaloneModule,
  BreadcrumbModule,
  ActionPanelModule,
  TimePickerModule,
  PageTitleModule,
  FileUploadModule,
  SwitchModule,
  AlertModule,
  BadgeModule
  
} from 'snl-complib';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ContentModalComponent } from './components/dropdown/content-modal/content-modal.component';
import { BoldPipe } from './pipes/bold.pipe';
import { NiceDateFormatPipe } from './pipes/nice-date-formet.pipe';
import { SearchDropdownInputComponent } from './components/search-dropdown-input/search-dropdown-input.component';
import { CommanConfirmantionModalComponent } from './components/comman-confirmantion-modal/comman-confirmantion-modal.component'
import { TextAreaComponent } from './components/textarea/textarea.component';
import { NotificationsCardComponent } from '../pages/notifications/notifications-tabs/notifications-card/notifications-card.component';

const snlComplibModule: any[] = [
  ButtonModule,
  RadioButtonModule,
  CheckboxModule,
  IconModule,
  LinkModule,
  TooltipModule,
  InputModule,
  PillsModule,
  AccordionModule,
  StatusModule,
  PageTabModule,
  CardModule,
  ExplodedDropdownModule,
  DatePickerModule,
  DateModule,
  ModalModule,
  NavigationModule,
  TableModule,
  DirectiveModule,
  DropdownModule,
  ProfileImageModule,
  RangeSliderModule,
  HeaderStandaloneModule,
  BreadcrumbModule,
  ActionPanelModule,
  TimePickerModule,
  PageTitleModule,
  FileUploadModule,
  SwitchModule,
  AlertModule,
  BadgeModule
];

@NgModule({
  declarations: [
    PageTitleComponent,
    DropdownComponent,
    ContentModalComponent,
    NotificationsCardComponent,
    BoldPipe,
    SearchDropdownInputComponent,
    CommanConfirmantionModalComponent,
    TextAreaComponent,
    NiceDateFormatPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ...snlComplibModule
  ],
  exports: [
    CommonModule,
    IonicModule,
    PageTitleComponent,
    DropdownComponent,
    ContentModalComponent,
    NotificationsCardComponent,
    SearchDropdownInputComponent,
    CommanConfirmantionModalComponent,
    TextAreaComponent,
    CommanConfirmantionModalComponent,
    BoldPipe,
    NiceDateFormatPipe,
    ...snlComplibModule
  ]
})
export class SharedModuleModule { }
