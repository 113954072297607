<snl-dropdown *ngIf="isDesktop" [ngClass]="{ 'error':isError && prevValue !== undefined }"
    [preSelectedItems] = "preSelect" 
    [label]="label"
    [buttonPrimary]="buttonPrimary"
    [buttonSecondary]="buttonSecondary"
    [options]="options"
    [multiple]="false" 
    (selectedValues)="selectedValuesChange($event)"
    (resetDropdown) ="resetDropdownChange($event)"
    [showAll]="true"
    [isFiltered]="false"
></snl-dropdown>
<ng-container *ngIf="reset && !isDesktop">
    <div class="input" [ngClass]="{'error': isError  && prevValue !== undefined}">
        <div class = 'css-container__dropdown css-container__dropdown-border' (click) = "onDropdownClick()">
            <span class="css-container__dropdown--label" [ngClass]="{'body-b1 top-left css-preselected-label': dropdownValue}">{{label}}</span>
            <span class="css-container__dropdown--labelT body-b1" *ngIf="dropdownValue">
                <span class="long-label">{{dropdownValue}}</span>
            </span>
            <span class="trailingIcon">
                <snl-icon icon="fa-solid fa-chevron-down"></snl-icon>
            </span>
        </div>
    </div>
</ng-container>