import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  
  constructor(private readonly http: HttpClient) { }



  fetchUserDetail(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/user-profile`);
  }
  fetchCountryList(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/master-data/countries`);
  }

  fetchUserApprovedDetail(validateProfile:boolean=false): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/user-profile/active-user` + (validateProfile ? `?validateProfile=1` : ''));
  }

  getCrewBasicNotifications(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/notifications/basic-notifications`);
  }

  fetchBankList(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/master-data/bank-list`);
  }

  fetchMaritalStatusList(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/master-data/marital-status`);
  }

  fetchRelationshipList(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/master-data/relationships`);
  }

  addUpdateUserProfileData(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/user-profile`, postData);
  }

  addBeneficiaryData(postData:any): Observable<any>{
      return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/beneficiary`, postData);
  }

  fetchFamilyList(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/family-member/list`);
  }

  fetchFamilyDetail(id:any): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/family-member/`+id);
  }
  fetchActivityDetail(id:any): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/activities/`+id);
  }

  fetchApprovedFamilyList(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/family-member`);
  }

  addFamilyData(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/family-member`, postData);
  }

  familyDelete(idDelete: string) {
    return  this.http.delete(`${environment.CREW_APP_BACKEND_API_BASE_URL}/family-member/${idDelete}`)
  }

  fetchBeneficiaryData(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/beneficiary`);
  }

  fetchBeneficiaryDetailData(param:any): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/beneficiary/`+param);
  }

  viewBeneficiaryDocument(params:any): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/upload-file/get-file`,{params, responseType: 'blob'},);
  }
  beneficiaryDelete(idDelete: string) {
    return  this.http.delete(`${environment.CREW_APP_BACKEND_API_BASE_URL}/beneficiary/${idDelete}`)
  }

  submitBeneficiaryData(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/beneficiary/submit-beneficiary`,postData);
  }

  saveAllotmentEntitled(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/beneficiary/save-allotment-entitled`,postData);
  }

  fetchPayslipDetail(): Observable<any>{
    return this.http.get(`${environment.CREW_APP_BACKEND_API_BASE_URL}/payslips`);
  }
  updateEmail(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/user-profile/request-otp`, postData);
  }
  verifyOtp(postData:any): Observable<any>{
    return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/user-profile/verify-otp`, postData);
  }


}
