<ng-container>
  <div class="header d-flex justify-content-between">
    <div class="header__left">
      <span class="sub-heading-s2 fw-700 color__primary">{{title}}</span>
    </div>
  </div>
  <div class="wrapper">
    <div class="content">
      <span class="body-b1 color__primary"></span>
      <div class="body-b1 color__primary" [innerHTML]="message"></div>
      <a *ngIf="!hideLoginAgainLink" class="text-decoration cursor-pointer color__primary_default" (click)="loginAgain()">Click here to login</a>
    </div>
  </div>
</ng-container>