import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'niceDateFormat'
})
export class NiceDateFormatPipe implements PipeTransform {
  transform(value:any) {

    const today = new Date();
    const yesterday = new Date(today);
    const date = new Date(value);
    yesterday.setDate(yesterday.getDate() - 1)

       if (date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth() && date.getDate() == today.getDate())
       return "Today";
     else if (date.getFullYear() == yesterday.getFullYear() && date.getMonth() == yesterday.getMonth() && date.getDate() == yesterday.getDate())
       return "Yesterday";
    else{
      return (new DatePipe("en-US")).transform(date, 'dd MMM y');
    }
 }
}