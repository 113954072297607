import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { CrewSelfService } from '../../services/crew-self.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public crewSelfService:CrewSelfService) {}

    canActivate(): Observable<boolean> | boolean {
       return this.crewSelfService.authGuard();
    }
}