export const STOLT_CONST = Object.freeze({
  SUCCESS_STATUS: 'success',

});

export const USER_STATUS = Object.freeze({
  APPROVED: 'Approved',
  PENDING_APPROVAL: 'Pending approval',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  REQUESTE_FOR_DELETION:'Submitted for deletion'
})

export const SUCCESS_MESSAGE = Object.freeze({
   DRAFT:"Your details were saved successfully.",
   PENDING_APPROVAL:"Your details were saved and submitted for approval.",
});

export const BTN_TEXT = Object.freeze({
  SAVE_AS_DRAFT: 'Save as draft',
  CANCEL_DRAFT: 'Cancel draft',
  UPDATE_DRAFT: 'Update draft',
  SAVE_AND_SUBMIT: 'Save as Submit',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
});

export const DOCUMENTS_STATUS = Object.freeze({
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
});

export const DOCUMENTS_TABS = Object.freeze([
    { key: 'IN_PROGRESS', value: 'In progress'},
    { key: 'APPROVED', value: 'Approved' },
    { key: 'REJECTED', value: 'Rejected' },
    { key: 'EXPIRED', value: 'Expired' }
]);



export const INPUT_ERROR_MESSAGE = Object.freeze({
  CHARACTOR_ONLY: 'You have added a number or a special character that is not allowed on this field. Delete the number or special character and continue typing.',
  NUMBER_ONLY: 'You have added a character or a special character that is not allowed on this field. Delete the character or special character and continue typing.',
  SPECIAL: 'You have added a special character that is not allowed on this field. Delete the special character and continue typing.',
  MAXLENGTH:'The maximum amount of characters is reached. You cannot type any more characters.'
})


export const DOCUMENT_CATEGORY_TYPE = {
  TRAVEL: "TRAVEL",
  MEDICAL: "MEDICAL",
  LICENSES: "LICENSES/CONTRACTS",
  TRAINING: "TRAINING",
};

export const MEDICAL_FIT_UNFIT_STATUS = {
  "FIT WITHOUT RESTRICTIONS": "FIT WITHOUT RESTRICTIONS",
  "UNFIT": "UNFIT",
  "FIT WITH RESTRICTIONS": "FIT WITH RESTRICTIONS",
  "NOT APPLICABLE": "NOT APPLICABLE"
};

export const MEDICAL_NEG_POS_STATUS = {
  NEGATIVE: "NEGATIVE",
  POSITIVE: "POSITIVE",
};

export const TRAVEL_DOC_TYPE = {
  PASSPORT: "PASSPORT",
  VISA: "VISA",
  VACCINATION: "VACC",
  SEAMANBOOK: "SBOOK"
}

export const TRAINING_INT_EXT_STATUS = {
  N: "INTERNAL",
  Y: "EXTERNAL"
};

export const VISA_ENTRY_TYPE = {
  "1 Entry": "1 Entry",
  "2 Entries": "2 Entries",
  "Multiple Entries": "Multiple Entries",
}

export const VISA_DURATION_TYPE = {
  "60 Days": "60 Days",
  "90 Days": "90 Days",
  "120 Days": "120 Days",
  "180 Days": "180 Days"
}


export const DOCUMENT_API_CONSTANT = {
  MEDICAL: "medical-type",
  TRAINING: "training-type",
  TRAVEL: "travel-list",
  LICENSES: "license-type",
  FALGSTATE: ""
}
interface DocumentCategoryDetails {
  key_code: string;
  key_desc: string;
}

export const DOCUMENT_CATEGORY_KEY_MAP: {
  [key: string]: DocumentCategoryDetails;
} = {
  MEDICAL: {
    key_code: 'MEDICALTYPE_CODE',
    key_desc: 'DESCRIPTION'
  },
  TRAINING: {
    key_code: 'TRAINING_CODE',
    key_desc: 'TRAINING_DESCRIPTION'
  },
  TRAVEL: {
    key_code: 'DOCCATEGORY_CODE',
    key_desc: 'DESCRIPTION'
  },
  LICENSES: {
    key_code: 'LICENSE_CODE',
    key_desc: 'LICENSE_DESCRIPTION'
  }
} as const;

// Define an interface for Document Fields
interface DocumentField {
  label: string;
  key: string;
  type: string;
  split: boolean;
  postion?: number; // Optional, since not all fields have this property
}

// Define an interface for Categories
type DocumentCategory = 'TRAVEL' | 'MEDICAL' | 'LICENSES' | 'TRAINING';


type DocumentFieldType = {
  label: string;
  key: string;
  type: string;
  options?: any;
  split: boolean;
  formControlName?: string,
  position?: number;
  condition_on?: string;
  optionKey?: string;
  exclude?: string[];
  excludeKey?: string;
  required?: boolean// Add optional properties if they exist
};

type DocumentFieldsMap = {
  [key in DocumentCategory]: {
    [key: string]: DocumentFieldType[];
  };
};

// Define the DOCUMENT_FIELDS constant with the appropriate type

export const ADD_DOCUMENT_FIELDS: any = {
  TRAVEL: {
    PASS: [
      { label: 'Passport number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true, key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SBOOK: [
      { label: "Seaman's document type", required: true, key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Document number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true,key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
     
    ],
    VISA: [
      { label: 'Visa type', key: 'SUB_TYPE_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Visa entry', key: 'VISA_ENTRY', type: "select",options:VISA_ENTRY_TYPE, split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER','VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa duration', key: 'VISA_DURATION', options:VISA_DURATION_TYPE, type: "select", split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER', 'VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa number', key: 'DOC_NO',required: true, type: "string", split: false },
      { label: 'Visa country', key: 'COUNTRY_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued country', key: 'VISADOC_COUNTRY',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
    ],
    VACC: [
      { label: "Vaccination type", required: true,key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Comments', key: 'COMMENTS',required: false, type: "string", split: true, exclude: ['VACCYF'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE', required: true,type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  MEDICAL: {
    PREEMPL: [
      { label: 'Fit/Unfit',required: true, key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS, split: true },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }
    ],
    DRUGALCO: [
      { label: 'Positve Negative',required: true, key: 'COMMENTS', type: "select", options: MEDICAL_NEG_POS_STATUS, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SUPPMED: [
      { label: 'Fit/Unfit', required: true,key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }

    ]
  },
  LICENSES: {
    FORM: [
      { label: 'Number', required: true,key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued at',required: true, key: 'ISSUE_AT', type: "select-child", options: [], split: false },
      { label: 'Issued by (optional)', key: 'ISSUE_BY', type: "string", split: false },
      { label: 'Issued date',required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  TRAINING: {
    FORM: [
      { label: 'Number',required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Start date',required: true, key: 'START_DATE', type: "date", split: false },
      { label: 'End date',required: true, key: 'END_DATE', type: "date", split: false },
      { label: 'Expiry date',required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
      { label: 'Internal or external',required: true, key: 'INTERNAL', type: "select", options: TRAINING_INT_EXT_STATUS, split: false },
      { label: 'Conducted at (optional)', key: 'CONDUCT_AT', type: "select-child", options: [], split: false },
    ]
  }

};

export const DOCUMENT_FIELDS = Object.freeze({
  TRAVEL: {
     PASS:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Passport number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
      ],
      SBOOK:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Seaman's document type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Document number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
      VISA:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Visa type',key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Visa entry',key:'COMMENTS',type:"string",split:true,postion:0,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa duration',key:'COMMENTS',type:"string",split:true,postion:1,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa number',key:'DOC_NO',type:"string",split:false},
        {label:'Visa country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued country',key:'VISADOC_COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
      ],
      VACC:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Vaccination type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Comment',key:'COMMENTS',type:"string",split:false,exclude:['VACCYF']},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
  },
  MEDICAL:{
    PREEMPL:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1},
    ],
    DRUGALCO:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Positve Negative',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
    ],
    SUPPMED:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1}
    ]
  },
  LICENSES: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Issued at',key:'ISSUE_AT_NAME',type:"string",split:false},
    {label:'Issued by',key:'ISSUE_BY',type:"string",split:false},
    {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
  ],
  TRAINING: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Start date',key:'START_DATE',type:"date",split:false},
    {label:'End date',key:'END_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
    {label:'Internal or external',key:'INTERNAL_NAME',type:"string",split:false},
    {label:'Conducted at',key:'CONDUCT_NAME',type:"string",split:false},
  ]
})


