import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-reporting',
  templateUrl: './open-reporting.component.html',
  styleUrls: ['./open-reporting.component.scss'],
})
export class OpenReportingComponent  implements OnInit {
  public title: string = 'Open reporting - Crew Self Service';

  ngOnInit() {}

}
